<template>
	<base-drawer>
		<w-flex column>
			<header style="margin-top: 42px;">
				<!-- <w-button
							@click="showDrawer = !showDrawer"
							outline="outline"
							color="primary"
							>Show drawer</w-button
						> -->

				<w-flex class="text-left">
					<w-button class="ma4 bg-main" @click="showAbout()">
						<w-icon class="mr1">wi-plus</w-icon>Add About
					</w-button>
					<div class="xs6 md6 lg4 xl6 pa1">
						<div class="">
							<w-select
								v-model="selectedAbout"
								:items="aboutList"
								outline="outline"
								>Choose About</w-select
							>
						</div>
					</div>
				</w-flex>
			</header>
			<main class="grow">
				<div class="ml5">
					<w-alert success icon-outside dismiss v-if="showSuccess">{{
						message
					}}</w-alert>
					<w-alert error icon-outside dismiss v-if="showError">{{
						message
					}}</w-alert>
				</div>

				<w-flex wrap class="ml4">
					<div class="xs12 md12 lg12 xl12">
						<w-card
							shadow
							class="xs12 md12 lg12 xl12 primary--bg white mb4 pl3 titleCard"
						>
							Makala Titles
							<div
								v-if="addMakalaVisible"
								class="setting bg-main white add"
								@click="addTitle()"
							>
								<i class="mdi mdi-plus"></i>
							</div>
						</w-card>
						<div v-if="!isLoading.titles">
							<w-card
								:tile="true"
								shadow
								class="xs12 md12 lg12 xl12 primary mt1 pa0 titleCard"
								v-for="x in makalaTitles"
								:key="x.id"
							>
								<p>
									<w-icon class="mr1">wi-check</w-icon>
									{{ x.makala_title }}
								</p>
								<div
									class="setting info--bg white edit"
									@click="viewMakala(x)"
								>
									<i class="mdi mdi-eye"></i>
								</div>
								<!-- <div
									class="setting info-dark2--bg white edit"
									@click="editTitle(x)"
								>
									<i class="mdi mdi-pencil"></i>
								</div> -->
								<div
									class="setting error--bg white delete"
									@click="confirmDelete(x.id)"
								>
									<i class="mdi mdi-delete"></i>
								</div>
							</w-card>
						</div>
						<div class="text-center" v-if="noresult.titles">
							<img
								src="../assets/noresult.png"
								class="noresult"
								alt=""
							/>
						</div>
						<div class="text-center" v-if="isLoading.titles">
							<img
								src="../assets/loader.gif"
								class="loader"
								alt=""
							/>
						</div>
					</div>
				</w-flex>
			</main>
		</w-flex>

		<w-dialog
			v-model="dialog.show"
			title="Warning"
			title-class="error"
			transition="bounce"
			persistent="persistent"
			:width="450"
			style="position: fixed;"
		>
			Are you sure you want to delete?
			<template #actions="">
				<div class="spacer"></div>
				<w-button
					class="mr2"
					@click="dialog.show = false"
					bg-color="error"
					>No</w-button
				>
				<w-button @click="deleteMakala()" bg-color="success"
					>Yes</w-button
				>
			</template>
		</w-dialog>
		<w-form action="#" method="post" @submit.prevent="sendMakala()">
			<w-dialog
				v-model="dialog.showForm"
				:fullscreen="true"
				transition="bounce"
				:width="dialog.width"
				:persistent="dialog.persistent"
				:persistent-no-animation="dialog.persistentNoAnimation"
				title-class="bg-main white"
				style="margin-top: 35px;"
			>
				<template #title="">
					<w-icon class="mr2">mdi mdi-plus</w-icon
					>{{ submitBtn == 'save' ? 'Add' : 'Change' }} Title
				</template>

				<div class="w-flex mt6 no-grow">
					<w-input
						v-model="titleName"
						shadow
						class="mb2"
						outline="outline"
						inner-icon-left="wi-check"
						label="Makala Title"
						:validators="[validators.required]"
					></w-input>
				</div>

				<div style="display: table;">
					<table>
						<tr>
							<td>
								<w-input
									outline="outline"
									v-model="contentInput"
									label="Enter Detail"
								></w-input>
							</td>
							<td>
								<w-select
									v-model="selectedCategoryType"
									:items="categoryType"
									outline="outline"
									>Item Type</w-select
								>
							</td>
							<td>
								<w-button
									primary
									class="pa3 mt3"
									@click="appendPost()"
									><w-icon>mdi mdi-plus</w-icon></w-button
								>
							</td>
						</tr>
					</table>
				</div>
				<w-flex wrap class="w-flex mt6 no-grow">
					<div
						v-for="post in postMakala"
						:key="post.id"
						:class="contentClass(post.type_id)"
						class="lg12 md12 sm12 xl12 xs12 mt3"
						style="width: 100%"
					>
						{{ post.desc }}
					</div>
				</w-flex>

				<template #actions="">
					<div class="spacer"></div>
					<w-button class="mr3" type="submit">{{
						submitBtn
					}}</w-button>
					<w-button bg-color="error" @click="dialog.showForm = false"
						>CLOSE</w-button
					>
				</template>
			</w-dialog>
		</w-form>

		<w-dialog
			v-model="dialog.showContent"
			:fullscreen="true"
			:width="1000"
			:persistent="false"
			title-class="bg-main white"
			transition="bounce"
		>
			<template #title="">
				<w-icon class="mr2">mdi mdi-tune</w-icon>Makala Content
			</template>

			<div class="xs12 md12 lg12 xl12 primary pa1">
				<!-- <w-tabs v-model="activeTabs" :items="tabs" center>
								<div
									:item-content="tabs"
									item-content.x
									:item="tabs"
									:index="tabs.x"
									:active="true"
								>
									Hello
								</div>
							</w-tabs>
							{{ activeTabs }} -->

				<w-flex wrap class="mt2">
					<div class="title-container">
						<w-card shadow bg-color="primary">{{
							makalaContentList.makala_title
						}}</w-card>
						<section>
							<div class="play-list row">
								<div
									v-for="(content,
									index) in makalaContentList.makala_content"
									:key="index"
									:class="contentClass(content.type_id)"
									class="lg12 md12 sm12 xl12 xs12 mt5"
									style="width: 100%"
								>
									{{ content.desc }}
								</div>
								<!-- <div
											class=" col-lg-6 col-md-12"
											v-for="(content,
											index) in makalaContentList"
											:key="index"
										>
											<w-card shadow class="pa0 ma2">
												{{ content.desc }}
											</w-card>
										</div> -->
							</div>
						</section>
					</div>

					<div
						class="text-center"
						v-if="noresult.audios"
						style="margin: 0 auto"
					>
						<img
							src="../assets/noresult.png"
							class="noresult"
							alt=""
						/>
					</div>
					<div
						class="text-center"
						v-if="isLoading.audios"
						style="margin: 0 auto"
					>
						<img src="../assets/loader.gif" class="loader" alt="" />
					</div>
				</w-flex>
			</div>

			<template #actions="">
				<div class="spacer"></div>
				<w-button @click="dialog.showContent = false">Close</w-button>
			</template>
		</w-dialog>

		

		<form action="#" method="post" @submit.prevent="sendAbout()">
		<w-dialog
			v-model="dialog.showAbout"
			:fullscreen="false"
			:width="300"
			:persistent="false"
			title-class="bg-main white"
			transition="bounce"
		>
			<template #title="">
				<w-icon class="mr2">mdi mdi-plus</w-icon>Add About
			</template>

			<div class="xs12 md12 lg12 xl12 primary pa1">
			
					
						<div class="ma2">
							<w-input type="text" v-model="aboutTitle" outline placeholder="About Title" required></w-input>
						</div>
						<div class="ma2">
							
						</div>
			</div>

			<template #actions="">
				<div class="spacer"></div>
				<w-button type="submit">SAVE</w-button>
				<w-button bg-color="error" class="ml2" @click="dialog.showAbout = false">Close</w-button>
			</template>
		</w-dialog>
		</form>
	</base-drawer>
</template>
<script>
import axios from 'axios';

export default {
	data: () => ({
		showDrawer: false,
		makalaContentList: [],
		categoryType: [],
		postMakala: [],
		selectedCategoryType: [],
		contentInput: '',
		submitBtn: 'save',
		makalaId: '',
		titleName: '',
		aboutId: 0,
		aboutTitle: '',
		aboutStatus: 'new',
		audioTitle: '',
		titleStatus: '',
		selectedAbout: [],
		selectedType: [],
		aboutList: [],
		duruusTypeList: [],
		makalaTitles: [],
		addMakalaVisible: false,
		statusCode: '',
		noresult: { audios: false, titles: false },
		showError: false,
		showSuccess: false,
		message: '',
		isLoading: { audios: false, titles: false },
		dialog: {
			width: 700,
			show: false,
			showForm: false,
			showContent: false,
			showAbout: false
		},
		validators: {
			required: (value) => !!value || 'This field is required',
		},
		showAudioList: true,
		showAudioForm: false,
		button1loading: false,
		btnAddAudio: 'SAVE',
		audioSuccess: false,
		audioError: false,
		tabs: [
			{ title: 'Audio List', content: 'All AUdios' },
			{ title: 'Add Audio', content: 'Add Audio' },
		],
		tabValue: 0,
	}),
	methods: {
		showAbout(){
			this.dialog.showAbout = true;
		},
		sendAbout(){
			if(this.aboutTitle != ""){
				let data = new FormData();
				data.append("about",this.aboutStatus)
				data.append('id',this.aboutId)
				data.append('title',this.aboutTitle);
				data.append('userId',7);

				axios.post("makala.php",data)
				.then(response=>{
					let result = response.data;
					if(result.status == 200){
						if(result.data.result==1){
							this.getAbout();
							this.dialog.showAbout = false;
							alert("About Added Successfull");
						}
						else{
							alert("About Not Inserted")
						}
						
					}
					else{
						alert("Fail To Process Request")
					}
				})
				.catch(()=>{
					alert("An Error Occured, Please Try Again Later")
				})
			}
		},
		appendPost() {
			this.postMakala.push({
				desc: this.contentInput,
				type_id: this.selectedCategoryType,
			});
			this.contentInput = '';
			this.selectedCategoryType = '';
		},
		contentClass(type) {
			type = Number(type);
			switch (type) {
				case 1:
					return 'sub';
				case 2:
					return 'desc';
				case 3:
					return 'ayah';
				case 4:
					return 'ayah-translate';
				case 5:
					return 'hadith';
				case 6:
					return 'picture';
				case 7:
					return 'link';
				default:
					return 'desc';
			}
		},

		getCategory() {
			axios.get('makala.php?about=contentTypes').then((data) => {
				var res = data.data;
				for (var i = 0; i < res.data.length; i++) {
					let cat = res.data[i];
					this.categoryType.push({
						label: cat.type_name,
						value: cat.type_id,
					});
				}
			});
		},
		getAbout() {
			axios.get('makala.php?about=all').then((data) => {
				var res = data.data;
				this.aboutList = [];
				for (var i = 0; i < res.data.length; i++) {
					let mk = res.data[i];
					this.aboutList.push({
						label: mk.about_title,
						value: mk.about_id,
					});
				}
			});
		},

		getMakala(about) {
			this.isLoading.titles = true;
			this.noresult.titles = false;
			axios
				.get('makala.php?status=all&aboutId=' + about)
				.then((data) => {
					let result = data.data;
					var code = result.status;
					this.isLoading.titles = false;
					if ((result.data && code == 200) || code == 201) {
						this.makalaTitles = result.data;
					} else {
						this.makalaTitles = [];
					}
				})
				.catch(() => {
					this.isLoading.titles = false;
					this.makalaTitles = [];
				});
		},
		processor() {
			if (this.selectedAbout != '') {
				this.addMakalaVisible = true;
				this.getMakala(this.selectedAbout);
			} else {
				this.addMakalaVisible = false;
			}
		},
		addTitle() {
			this.submitBtn = 'SAVE';
			this.titleStatus = 'new';
			this.titleName = '';
			this.dialog.showForm = true;
		},
		editTitle(title) {
			this.makalaId = title.title_id;
			this.titleName = title.title_name;
			this.titleStatus = 'old';
			this.submitBtn = 'UPDATE';
			this.dialog.showForm = true;
		},
		sendMakala() {
			if (this.titleName != '') {
				this.dialog.showForm = false;
				var formData = new FormData();
				formData.append('makalaStatus', 'new');
				formData.append('title', this.titleName);
				formData.append('desc', JSON.stringify(this.postMakala));
				formData.append('userId', 7);
				formData.append('aboutId', this.selectedAbout);

				axios
					.post('makala.php', formData)
					.then((data) => {
						if (data.status == 200) {
							var x =
								this.submitBtn == 'SAVE' ? 'Added' : 'Changed';
							this.message = 'Makala ' + x + ' Successful !!!';
							this.showSuccess = true;
							this.titleName = '';
							this.processor();
						}
					})
					.catch((error) => {
						alert(error);
					});
			}
		},
		viewMakala(makala) {
			this.makalaContentList = makala;
			this.dialog.showContent = true;
		},
		confirmDelete(id) {
			this.makalaId = id;
			this.dialog.show = true;
		},
		deleteMakala() {
			axios
				.post(
					'makala.php',
					JSON.stringify({
						makalaStatus: 'block',
						makalaId: this.makalaId,
					})
				)
				.then((data) => {
					if (data.status == 200) {
						this.dialog.show = false;
						this.showSuccess = true;
						this.message = 'Makala Deleted Successful !!!';
						setTimeout(function() {
							this.showSuccess = false;
						}, 2000);
						this.processor();
					}
				})
				.catch(() => {
					this.message =
						'Fail To Delete Makala, Please Try Again !!!';
					this.showError = true;
				});
		},
	},
	watch: {
		tabValue() {
			if (this.tabValue == 0) {
				this.loadScript += this.loadScript;
				this.btnAddAudio = 'SAVE';
				this.audioSuccess = false;
				this.audioError = false;
				this.audioTitle = '';
			}
		},
		selectedAbout() {
			this.processor();
		},
		selectedType() {
			this.processor();
		},
		makalaTitles() {
			if (this.makalaTitles) {
				this.noresult.titles = this.makalaTitles.length == 0;
			} else {
				this.noresult.titles = true;
			}
		},
	},

	mounted() {
		this.getAbout();
		this.getCategory();
	},
};
</script>
<style scoped>
* {
	text-decoration: none;
}
.title-container {
	margin-top: 19px;
	text-align: left;
	padding-right: 17px;
}
.title-container .col6 div {
	font-size: 15px;
	cursor: pointer;
	letter-spacing: 1px;
	font-weight: 100;
}

/* ###################################################################### */

@import '//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/css/font-awesome.css';
.screen-reader-text {
	/* Reusable, toolbox kind of class */
	position: absolute;
	top: -9999px;
	left: -9999px;
}

.disabled {
	color: #666;
	cursor: default;
}

.show {
	display: inline-block !important;
}

.title-container {
	font-family: arial, helvetica, sans-serif;
	font-size: 1em;
	margin: 0 auto;
	/* width: 500px; */
	width: 100%;
}
.play-list-row {
	position: relative;
}

.title-container .play-list .play-list-row .track-title .activeDot {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 10px;
	display: none;
}
.title-container .play-list .play-list-row .track-title a {
	display: block;
	position: absolute;
	top: 0px;
	right: 0px;
	left: 26px;
	bottom: 0px;
	line-height: 38px;
	padding-left: 5px;
	transition: 1s ease-in-out infinite;
}
.title-container .play-list .play-list-row .track-title.active-track a {
	font-weight: bold;
	color: red;
}
.title-container
	.play-list
	.play-list-row
	.track-title.active-track
	.activeDot {
	display: block;
}
.scale_motion {
	animation-name: track;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}
@keyframes track {
	0% {
		padding-top: 3px;
	}
	50% {
		padding-top: 18px;
	}
	100% {
		padding-top: 3px;
	}
}
.play-list-row {
	word-wrap: break-word;
	overflow: hidden;
	height: 40px;
	line-height: 30px;
	font-size: 16px;
}
.btnDownload {
	position: absolute;
	top: 40px;
	right: 30px;
	width: 40px;
	height: 40px;
	background: #19bc9d;
	color: white;
	text-align: center;
	transition: 0.4s;
	cursor: pointer;
	line-height: 40px;
}
.play-list-row:hover .btnDownload {
	top: 0px;
}

.desc {
	color: rgba(0, 0, 0, 0.8);
}
.ayah {
	color: red;
	text-align-last: right;
}
.ayah-translate {
	color: blue;
}
.hadith {
	color: green;
}
.ayah,
.desc,
.ayah,
.ayah-translate,
.hadith {
	text-align: justify;
	text-transform: uppercase;
}
.sub {
	text-align: left;
	font-weight: bold;
	padding-top: 40px;
}
</style>
