<template>
	<w-dialog
		:model-value="showDialog"
		:title="type == 'new' ? 'Add Book' : 'Edit Book'"
		title-class="bg-main white"
		transition="slide-down"
		persistent="persistent"
		:fullscreen="false"
		:persistent-no-animation="false"
		:width="500"
		style="position:fixed;"
	>
		<w-alert error plain v-if="dialogError"
			><p>
				Fail To {{ type == 'new' ? 'Add' : 'Change' }} Book
			</p></w-alert
		>
		<w-alert success plain v-if="dialogSuccess"
			><p>
				Book {{ type == 'new' ? 'Added' : 'Changed' }} Successful !!!
			</p></w-alert
		>
		<w-form
			action="#"
			method="post"
			@submit.prevent="addBook()"
			class="mt2"
		>
			<w-input
				outline="outline"
				label="Book Title"
				color="text-main"
				inner-icon-left="mdi mdi-account"
				v-model="title"
				:validators="[validators.required]"
				accept="application/pdf"
			/>
			<w-textarea
				outline="outline"
				label="Desciption"
				Placeholder="Book Detail"
				color="text-main"
				class="mt4"
				inner-icon-left="mdi mdi-book"
				v-model="detail"
				:validators="[validators.required]"
			/>
			<input
				type="file"
				outline="outline"
				color="text-main"
				class="mt4"
				inner-icon-left="mdi mdi-file"
				ref="file"
				v-on:change="handleFileUpload()"
				:validators="[validators.required]"
			/>

			<w-flex class="mt6 text-center">
				<w-button class="" @click="$emit('closed')" bg-color="error"
					>Close</w-button
				>
				<w-button
					v-if="type == 'new'"
					type="submit"
					class="ml5 bg-main"
					bg-color="teal"
					color="white"
					:loading="isLoading"
					>SAVE</w-button
				>
				<w-button
					v-if="type == 'edit'"
					type="submit"
					class="ml5 bg-main"
					bg-color="teal"
					color="white"
					:loading="isLoading"
					>UPDATE</w-button
				>
			</w-flex>
		</w-form>
	</w-dialog>
</template>
<script>
import axios from 'axios';
export default {
	props: {
		showDialog: {
			type: Boolean,
			required: true,
			validators: function(value) {
				return value === true || value === false;
			},
		},
		content: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			type: 'new',
			id: null,
			title: '',
			detail: '',
			pdf: undefined,
			isLoading: false,
			validators: {
				required: (value) => !!value || 'This field is required',
			},
			dialogError: false,
			dialogSuccess: false,
		};
	},
	methods: {
		handleFileUpload() {
			this.pdf = this.$refs.file.files[0];
		},
		addBook() {
			if (
				this.title != '' &&
				this.detail != ''
			) {
				
				this.isLoading = true;
				let formData = new FormData();
				formData.append(
					'status',
					this.type == 'new' ? 'new' : 'old'
				);
				formData.append('book', this.type=='new'?this.pdf:this.id);
				formData.append('title', this.title);
				formData.append('detail', this.detail);
				axios
					.post('book.php', formData)
					.then((data) => {
						this.isLoading = false;
						let result = data.data;
						if (result.status == 200) {
							this.$emit('load-data', data.data);
							this.dialogError = false;
							this.dialogSuccess = true;
						} else {
							this.dialogError = true;
							this.dialogSuccess = false;
						}
					})
					.catch((error) => {
						this.isLoading = false;
						alert(error);
					});
			}
		},
	},
	watch: {
		showDialog() {
			this.dialogSuccess = false;
			this.dialogError = false;
			let book = this.content;
			this.id = book.id;
			this.type = book.type;
			this.title = book.title;
			this.detail = book.detail;
			this.pdf = book.pdf;
		},
	},
};
</script>
<style scoped></style>
