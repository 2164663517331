<template>
	<base-drawer>
		<w-flex column>
			<title-header title="MASHAIRI"></title-header>
			<main class="grow">
				<w-flex wrap grow>
					<div class="xs12 md12 lg12 xl12" style="overflow: auto;">
						<w-card
							shadow
							class="xs12 md12 lg12 xl12 primary--bg white mb4 pl3 titleCard"
						>
							Mashairi List
							<div
								class="setting bg-main white add ml3"
								@click="showForm = true"
							>
								<i class="mdi mdi-plus"></i>
							</div>
						</w-card>
						<mashairi-list
							@edit-shairi="editShairi"
							:is-changed="dataChanged"
						/>
						<mashairi-form
							:content="shairiContent"
							:show-dialog="showForm"
							@closed="closeForm"
							@load-data="loadData"
						/>
					</div>
				</w-flex>
			</main>
		</w-flex>
	</base-drawer>
</template>
<script>
import MashairiList from '../components/mashairi/list.vue';
import MashairiForm from '../components/mashairi/form.vue';
export default {
	components: { MashairiList, MashairiForm },
	data: () => ({
		showForm: false,
		noresult: false,
		isLoading: false,
		dataChanged: 0,
		shairiContent: {
			id: null,
			type: 'new',
			title: '',
			detail: ''
		},
	}),

	methods: {
		closeForm() {
			this.showForm = false;
			this.shairiContent = this.defaultContent();
		},
		editShairi(shairi) {
			this.showForm = true;

			this.shairiContent = {
				type: 'edit',
				id: shairi.id,
				title: shairi.title,
				detail: shairi.content
			};
		},
		loadData() {
			this.dataChanged += 1;
		},
		defaultContent() {
			return {
				id: null,
				type: 'new',
				title: '',
				detail: ''
			};
		},
	},
};
</script>
<style scoped></style>
