<template>
	<base-drawer>
		<w-flex column>
			<title-header title="QARI"></title-header>
			<main class="grow">
				<w-flex wrap grow>
					<div class="xs12 md12 lg12 xl12" style="overflow: auto;">
						<w-card
							shadow
							class="xs12 md12 lg12 xl12 primary--bg white mb4 pl3 titleCard"
						>
							Qari List
							<div
								class="setting bg-main white add ml3"
								@click="showForm = true"
							>
								<i class="mdi mdi-plus"></i>
							</div>
						</w-card>
						<qari-list
							@edit-qari="editQari"
							:is-changed="dataChanged"
						/>
						<qari-form
							:content="qariContent"
							:show-dialog="showForm"
							@closed="closeForm"
							@load-data="loadData"
						/>
					</div>
				</w-flex>
			</main>
		</w-flex>
	</base-drawer>
</template>
<script>
import QariList from '../components/qari/QariList.vue';
import QariForm from '../components/qari/QariForm.vue';
export default {
	components: { QariList, QariForm },
	data: () => ({
		showForm: false,
		noresult: false,
		isLoading: false,
		dataChanged: 0,
		qariContent: {
			id: null,
			type: 'new',
			name: '',
			country: ''
		},
	}),

	methods: {
		closeForm() {
			this.showForm = false;
			this.qariContent = this.defaultContent();
		},
		editQari(qari) {
			this.showForm = true;

			this.qariContent = {
				type: 'edit',
				id: qari.id,
				name: qari.name,
				country: qari.country
			};
		},
		loadData() {
			this.dataChanged += 1;
		},
		defaultContent() {
			return {
				id: null,
				type: 'new',
				name: '',
				country: ''
			};
		},
	},
};
</script>
<style scoped></style>
