import { createRouter, createWebHistory } from 'vue-router';

///////////// PAGE ROUTER
import Login from './pages/Login.vue';
// import Recover from './pages/Recover.vue';
import Home from './pages/home.vue';
import Shekh from './pages/shekh.vue';
import Duruus from './pages/duruus.vue';
import Books from './pages/books.vue';
import Khutba from './pages/khutba.vue';
import Makala from './pages/makala.vue';
import Nasaha from './pages/nasaha.vue';
import Darsa from './pages/darsa.vue';
import Mashairi from './pages/mashairi.vue';
import Mihadhara from './pages/mihadhara.vue';
import Player from './pages/player.vue';
import Quran from './pages/quran.vue';
import Qaris from './pages/qari.vue';
// import Radio from './pages/radio.vue';
import Ruduud from './pages/ruduud.vue';
// import Subscriber from './pages/subscribers.vue';
import Account from './pages/account.vue';
import Notification from './pages/notification.vue';

const router = createRouter({
	history: createWebHistory(),
	// base: process.env.BASE_URL,
	publicPath: '/Attamayyuz/',
	routes: [
		{ path: '/', component: Login },
		// { path: '/recover', component: Recover },
		{ path: '/dashboard', component: Home },
		{ path: '/shekh', component: Shekh },
		{ path: '/duruus', component: Duruus },
		{ path: '/books', component: Books },
		{ path: '/khutba', component: Khutba },
		{ path: '/makala', component: Makala },
		{ path: '/mihadhara', component: Mihadhara },
		{ path: '/player', component: Player },
		{ path: '/quran', component: Quran },
		{ path: '/qari', component: Qaris },
		{ path: '/ruduud', component: Ruduud },
		{ path: '/nasaha', component: Nasaha },
		{ path: '/darsa', component: Darsa },
		// { path: '/radio', component: Radio },
		// { path: '/subscribers', component: Subscriber },
		{ path: '/mashairi', component: Mashairi },
		{ path: '/account', component: Account },
		{ path: '/notification', component: Notification },
		{ path: '/notification', component: Notification },
		{ path: '/:NotFound*', component: Home }
	],
});

export default router;
