import { createApp, h } from 'vue';
import App from './App.vue';
import WaveUI from 'wave-ui/src/wave-ui';
import '@mdi/font/css/materialdesignicons.min.css';
import router from './router';
import './axios';
import store from './vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

///////////// IMPORT PAGES COMPONENT
import BaseDrawer from './components/BaseDrawer.vue';
import toolbar from './components/toolbar.vue';
import Header from './components/header.vue';
import sidemenu from './components/sidemenu.vue';
import loader from './components/loader.vue';
import confirm from './components/confirm.vue';
import Card from './components/Card.vue';

const app = createApp({
	render: () => h(App),
});
// app.config.globalProperties.globalUrl = 'http://localhost/myAPI/action/';
// app.config.globalProperties.globalUrl =
// 	'https://service.attamayyuz.or.tz/action/';

new WaveUI(app, {
	// Some Wave UI options.
});

app.component('TitleHeader', Header);
app.component('toolbar', toolbar);
app.component('sidemenu', sidemenu);
app.component('BaseDrawer', BaseDrawer);
app.component('siteLoader', loader);
app.component('confirm', confirm);
app.component('card-content', Card);
app.component('QuillEditor', QuillEditor)
app.config.productionTip = false;
app.use(router);
app.use(store);
app.mount('#app');
