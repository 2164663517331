<template>
	<base-drawer>
		<w-flex column>
			<header style="margin-top: 42px;">
				<w-flex class="wrap no-grow text-left">
					<div class="xs6 md6 lg4 xl6 pa1">
						<div class="">
							<w-select
								v-model="selectedShekh"
								:items="shekhList"
								outline="outline"
								>Choose Shekh</w-select
							>
						</div>
					</div>
					<div class="xs6 md6 lg4 xl6 pa1">
						<div class="">
							<w-select
								v-model="selectedType"
								:items="duruusTypeList"
								outline="outline"
								>Duruus Type</w-select
							>
						</div>
					</div>
				</w-flex>
			</header>
			<main class="grow">
				<div class="ml5">
					<w-alert success icon-outside dismiss v-if="showSuccess">{{
						message
					}}</w-alert>
					<w-alert error icon-outside dismiss v-if="showError">{{
						message
					}}</w-alert>
				</div>

				<w-flex wrap class="ml4">
					<div class="xs12 md12 lg12 xl12">
						<w-card
							shadow
							class="xs12 md12 lg12 xl12 primary--bg white mb4 pl3 titleCard"
						>
							Duruus Titles
							<div
								v-if="addTitleVisible"
								class="setting bg-main white add"
								@click="addTitle()"
							>
								<i class="mdi mdi-plus"></i>
							</div>
						</w-card>
						<div v-if="!isLoading.titles">
							<w-card
								:tile="true"
								shadow
								class="xs12 md12 lg12 xl12 primary mt4 pa0 titleCard"
								style="border-left: 3px solid darkcyan;"
								v-for="(x, index) in duruusTitles"
								:key="index"
							>
								<p>
									<w-icon class="mr1" style="color: darkcyan;"
										>wi-check</w-icon
									>
									{{ x.title_name }}
								</p>
								<div
									class="setting info--bg white view"
									@click="viewDuruus(x.title_id)"
								>
									<i class="mdi mdi-eye"></i>
								</div>
								<div
									class="setting info-dark2--bg white edit"
									@click="editTitle(x)"
								>
									<i class="mdi mdi-pencil"></i>
								</div>
								<div
									class="setting error--bg white delete"
									@click="confirmDelete(x.title_id)"
								>
									<i class="mdi mdi-delete"></i>
								</div>
							</w-card>
						</div>
						<div class="text-center" v-if="noresult.titles">
							<img
								src="../assets/noresult.png"
								class="noresult"
								alt=""
							/>
						</div>
						<div class="text-center" v-if="isLoading.titles">
							<img
								src="../assets/loader.gif"
								class="loader"
								alt=""
							/>
						</div>
					</div>
				</w-flex>
			</main>

			<w-dialog
				v-model="dialog.show"
				title="Warning"
				title-class="error"
				transition="bounce"
				persistent="persistent"
				:width="450"
				style="position: fixed;"
			>
				Are you sure you want to delete?
				<template #actions="">
					<div class="spacer"></div>
					<w-button
						class="mr2"
						@click="dialog.show = false"
						bg-color="error"
						>No</w-button
					>
					<w-button @click="deleteDuruus()" bg-color="success"
						>Yes</w-button
					>
				</template>
			</w-dialog>
			<w-form action="#" method="post" @submit.prevent="sendTitle()">
				<w-dialog
					v-model="dialog.showForm"
					transition="bounce"
					:width="dialog.width"
					:persistent="dialog.persistent"
					:persistent-no-animation="dialog.persistentNoAnimation"
					title-class="bg-main white"
					style="position: fixed;"
				>
					<template #title="">
						<w-icon class="mr2">mdi mdi-plus</w-icon
						>{{ submitBtn == 'save' ? 'Add' : 'Change' }} Title
					</template>

					<div class="w-flex mt6 no-grow">
						<w-input
							v-model="titleName"
							shadow
							class="mb2"
							outline="outline"
							inner-icon-left="wi-check"
							label="Title Name"
							:validators="[validators.required]"
						></w-input>
					</div>
					<template #actions="">
						<div class="spacer"></div>
						<w-button class="mr3" type="submit">{{
							submitBtn
						}}</w-button>
						<w-button
							bg-color="error"
							@click="dialog.showForm = false"
							>CLOSE</w-button
						>
					</template>
				</w-dialog>
			</w-form>

			<w-dialog
				v-model="dialog.showDuruus"
				:fullscreen="true"
				:width="1000"
				:persistent="false"
				title-class="bg-main white"
				transition="bounce"
				style="padding-bottom: 80px;"
			>
				<template #title="">
					<w-icon class="mr2">mdi mdi-tune</w-icon>Duruus Audios
				</template>

				<div class="xs12 md12 lg12 xl12 primary pa1">
					<!-- <w-tabs v-model="activeTabs" :items="tabs" center>
								<div
									:item-content="tabs"
									item-content.x
									:item="tabs"
									:index="tabs.x"
									:active="true"
								>
									Hello
								</div>
							</w-tabs>
							{{ activeTabs }} -->
					<w-tabs
						:model-value="tabValue"
						v-model="tabValue"
						:items="tabs"
						:center="true"
						card="card"
					>
						<template #item-title="{ item,index }">
							<w-icon :class="index == 2 ? 'primary' : 'pink'">{{
								index == 2 ? 'mdi mdi-star' : 'mdi mdi-heart'
							}}</w-icon>

							<span :class="index == 2 ? 'primary' : 'pink'">{{
								item.title
							}}</span>
						</template>

						<template #item-content="{ index }">
							<div class="title3">
								<!-- {{ item.content }}.{{ index + '' + active }} -->
							</div>
							<w-flex wrap class="mt2" v-if="index == 1">
								<div
									class="title-container"
									style="width: 100%;"
								>
									<div
										style="display: none;padding: 0px;margin: 0px;width: 100%;"
										class="audioError"
									>
										<w-alert
											error
											icon-outside
											color="error"
											style="margin: 10px;"
											><div>Error</div></w-alert
										>
									</div>
									<section>
										<w-flex wrap class="play-list">
											<div
												class="lg12 md12 sm12 xs12"
												v-for="(audio,
												index) in audioList"
												:key="audio.resource_id"
											>
												<card-content
													class="play-list-row titleCard"
									
												>
													<div
														class="small-toggle-btn" :class="{'active':index==activeIndex && activeKey && activeId==audio.resource_id}"
													>
														<i
															class="small-play-btn" :class="{'small-pause-btn':index==activeIndex && activeKey && activeId==audio.resource_id}"
															><span
																class="screen-reader-text"
																>Small toggle
																button</span
															></i
														>
													</div>
													<div
														class="track-number"
													></div>
													<div
														class="track-title text-uppercase"
													>
														<a
															class="playlist-track"
															href="#"
														
															@click="playNow(index)"
														>
															{{
																audio.resource_name
															}}</a
														>
														<span
															class="fa fa-bullseye scale_motion" :class="{'activeDot': index==activeIndex && activeKey && activeId==audio.resource_id}"
														></span>
														<div
															title="Edit"
															class="setting info-dark2--bg white edit"
															@click="
																editAudioTab(
																	audio
																)
															"
														>
															<i
																class="mdi mdi-pencil"
															></i>
														</div>
														<div
															title="delete"
															class="setting error--bg white delete"
															@click="
																deleteAudio(
																	audio.resource_id
																)
															"
														>
															<i
																class="mdi mdi-delete"
															></i>
														</div>
													</div>
												</card-content>
											</div>
										</w-flex>
									</section>
								</div>

								<div
									class="text-center"
									v-if="noresult.audios"
									style="margin: 0 auto"
								>
									<img
										src="../assets/noresult.png"
										class="noresult"
										alt=""
									/>
								</div>
								<div
									class="text-center"
									v-if="isLoading.audios"
									style="margin: 0 auto"
								>
									<img
										src="../assets/loader.gif"
										class="loader"
										alt=""
									/>

									<section
										:class="{
											showPlayer: showPlayer,
										}"
										style="position:fixed;left:0px;right: 0px; height: 53px;box-shadow: 0 0 2px cyan inset;"
									>
										<div
											:class="{
												player: true,
												showPlayer: showPlayer,
											}"
										>
											<div class="sub-container">
												<div class="large-toggle-btn">
													<i class="large-play-btn"
														><span
															class="screen-reader-text"
															>Large toggle
															button</span
														></i
													>
												</div>
												<!-- /.play-box -->

												<div class="info-box">
													<div class="track-info-box">
														<div
															class="track-title-text"
														></div>
														<div class="audio-time">
															<span
																class="current-time"
																>00:00</span
															>
															/
															<span
																class="duration"
																>00:00</span
															>
														</div>
													</div>
													<!-- /.info-box -->

													<div class="progress-box">
														<div
															class="progress-cell"
														>
															<div
																class="progress"
															>
																<div
																	class="progress-buffer"
																></div>
																<div
																	class="progress-indicator"
																></div>
															</div>
														</div>
													</div>
												</div>

												<div class="controls-box">
													<i
														class="previous-track-btn disabled"
														><span
															class="screen-reader-text"
															>Previous track
															button</span
														></i
													>
													<i class="next-track-btn"
														><span
															class="screen-reader-text"
															>Next track
															button</span
														></i
													>
												</div>
											</div>
										</div>
									</section>
								</div>
							</w-flex>
							<w-flex
								wrap
								class="mt2 justify-center mt10"
								v-if="index == 2"
							>
								<div
									class="xs12 md6 lg4 xl12 primary pa2 titleCard"
								>
									<w-card shadow class="m2" bg-color="white">
										<w-alert success v-if="audioSuccess"
											>Audio
											{{
												btnAddAudio == 'SAVE'
													? 'Added'
													: 'Changed'
											}}
											Successful</w-alert
										>
										<w-alert error v-if="audioError"
											>Fail To
											{{
												btnAddAudio == 'SAVE'
													? 'Add'
													: 'Change'
											}}
											Audio,
											{{ audioErrorMessage }}</w-alert
										>
										<w-form
											action="#"
											method="post"
											@submit.prevent="sendAudio()"
											style="margin: 0 auto;"
										>
											<w-input
												v-model="audioTitle"
												type="text"
												label="Audio Title"
												outline="outline"
												class="mb4"
												:validators="[
													validators.required,
												]"
											/>
											<input
												type="file"
												outline="outline"
												id="file"
												ref="file"
												required="true"
												v-on:change="handleFileUpload()"
											/>
											<w-button
												class="ma1"
												type="submit"
												bg-color="primary"
												:loading="button1loading"
											>
												<w-icon class="mr1"
													>wi-check</w-icon
												>{{ btnAddAudio }}
											</w-button>
										</w-form>
									</w-card>
								</div>
							</w-flex>
						</template>
					</w-tabs>
				</div>

				<template #actions="">
					<div class="spacer"></div>
					<w-button @click="dialog.showDuruus = false"
						>Close</w-button
					>
				</template>
			</w-dialog>
		</w-flex>
	</base-drawer>
</template>
<script>
import cookie from 'vue-cookies';
import axios from 'axios';

export default {
	data: () => ({
		showPlayer: false,
		cookieFound: false,
		showDrawer: false,
		submitBtn: '',
		titleId: '',
		titleName: '',
		audioTitleId: '',
		audioTitle: '',
		audioFile: '',
		file: undefined,
		titleStatus: '',
		selectedShekh: [],
		selectedType: [],
		shekhList: [],
		duruusTypeList: [],
		duruusTitles: [],
		audioList: [],
		addTitleVisible: false,
		statusCode: '',
		noresult: { audios: false, titles: false },
		showError: false,
		showSuccess: false,
		message: '',
		isLoading: { audios: false, titles: false },
		dialog: { width: 500, show: false, showForm: false, showDuruus: false },
		validators: {
			required: (value) => !!value || 'This field is required',
		},
		showAudioList: true,
		showAudioForm: false,
		button1loading: false,
		btnAddAudio: 'SAVE',
		audioSuccess: false,
		audioError: false,
		audioErrorMessage: '',
		tabs: [
			{ title: 'Audio List', content: 'All AUdios' },
			{ title: 'Add Audio', content: 'Add Audio' },
		],
		tabValue: 0,
	}),
	methods: {
		toggleDrawer(res) {
			this.showDrawer = res;
		},
		playNow(i) {
			let d = this.selectedType;
			let s = this.selectedShekh;
			let t = this.titleId;
			this.$store.state.player.active = i;
			this.$store.state.player.audioClicked +=1;
			this.$store.state.playlist = this.audioList;
			
			this.$store.state.player.key = 'duruus';
			this.$store.state.player.path="duruus/"+d+'/'+s+'/'+t+'/';
			// console.log(JSON.stringify(this.audioList));
			this.$store.state.player.showPlayer = true;
		},
		audioPath(filename) {
			let d = this.selectedType;
			let s = this.selectedShekh;
			let t = this.titleId;

			return (
				'https://resource.attamayyuz.or.tz/audio/duruus/' +
				d +
				'/' +
				s +
				'/' +
				t +
				'/' +
				filename
			);
		},
		downloadAudio(file, nm) {
			let d = cookie.get('confd').type_id;
			let s = cookie.get('confs').shekh_id;
			let t = this.$route.params.audioId;
			window.location.href =
				'https://resource.attamayyuz.or.tz/test.php?t=d&ty=' +
				d +
				'&sh=' +
				s +
				'&tt=' +
				t +
				'&file=' +
				file +
				'&name=' +
				nm;
		},
		sendAudio() {
			if (
				(this.btnAddAudio == 'UPDATE' &&
					this.audioTitle != '' &&
					this.audioTitleId != '') ||
				(this.titleId != '' &&
					this.audioTitle != '' &&
					this.file &&
					this.selectedShekh != '' &&
					this.selectedType != '')
			) {
				this[`button1loading`] = true;
				var formData = new FormData();
				formData.append('titleName', this.audioTitle);
				if (this.btnAddAudio == 'SAVE') {
					formData.append('status', 'new');
					formData.append('titleId', this.titleId);
					formData.append('audio', this.file);
					formData.append('shekhId', this.selectedShekh);
					formData.append('duruusId', this.selectedType);
					formData.append('userId', 7);
				} else if (this.btnAddAudio == 'UPDATE') {
					formData.append('status', 'old');
					formData.append('audioId', this.audioTitleId);
				}
				axios
					.post('duruus.php', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((data) => {
						this[`button1loading`] = false;
						let resp = data.data;
						// alert(JSON.stringify(resp));
						if (resp.status == 200) {
							this.viewDuruus(this.titleId);
							this.audioSuccess = true;
							this.audioError = false;
						} else {
							this.audioErrorMessage = resp.status_message;
							this.audioError = true;
						}
					})
					.catch((error) => {
						alert(error);
						this[`button1loading`] = false;
						this.audioError = true;
					});
			} else {
				this.audioError = true;
			}
		},
		deleteAudio(id) {
			let conf = confirm('Duruus Audio Will Be Deleted !!!');
			if (conf) {
				axios
					.post(
						'duruus.php',
						JSON.stringify({
							audio_status: 'block',
							resource_id: id,
						})
					)
					.then((data) => {
						let res = data.data;
						if (res.status == 200) {
							this.viewDuruus(this.titleId);
							alert('Duruus Audio Deleted Successfull!!!');
						} else {
							alert(
								'Fail To Delete Duruus Audio, Please Try Again Letter !!!'
							);
						}
					})
					.fetch(() => {
						alert(
							'Fail To Delete Audio, Please Try Again letter!!!'
						);
					});
			}
		},
		editAudioTab(x) {
			this.tabValue = 1;
			this.audioTitle = x.resource_name;
			this.audioTitleId = x.resource_id;
			this.btnAddAudio = 'UPDATE';
			// this.tabs[1].title = 'Edit Audio';
		},
		handleFileUpload() {
			this.file = this.$refs.file.files[0];
		},
		getShekh() {
			axios
				.get('shekh.php?status=all')
				.then((data) => {
					var res = data.data;
					if (res.status == 200) {
						for (var i = 0; i < res.data.length; i++) {
							let sh = res.data[i];
							this.shekhList.push({
								label: sh.shekh_name,
								value: sh.shekh_id,
							});
						}
					}
				})
				.catch((error) => {
					alert(error);
				});
		},
		getDuruusType() {
			axios
				.get('duruus_type.php?status=all')
				.then((data) => {
					var res = data.data;
					if (res.status == 200) {
						for (var i = 0; i < res.data.length; i++) {
							let ty = res.data[i];
							this.duruusTypeList.push({
								label: ty.type_name,
								value: ty.type_id,
							});
						}
					} else {
						alert(data.message);
					}
				})
				.catch((error) => {
					alert(error);
				});
		},
		getTitles(shekh, type) {
			this.isLoading.titles = true;
			this.noresult.titles = false;
			axios
				.get(
					'duruus_title.php?status=byShekh&sid=' +
						shekh +
						'&did=' +
						type
				)
				.then((data) => {
					let result = data.data;
					var code = result.status;
					this.isLoading.titles = false;
					if ((result.data && code == 200) || code == 201) {
						this.duruusTitles = result.data;
					} else {
						this.duruusTitles = [];
					}
				})
				.catch(() => {
					this.isLoading.titles = false;
					this.duruusTitles = [];
				});
		},
		processor() {
			if (this.selectedType != '' && this.selectedShekh != '') {
				this.addTitleVisible = true;
				this.getTitles(this.selectedShekh, this.selectedType);
			} else {
				this.addTitleVisible = false;
			}
		},
		addTitle() {
			this.submitBtn = 'SAVE';
			this.titleStatus = 'new';
			this.titleName = '';
			this.dialog.showForm = true;
		},
		editTitle(title) {
			this.titleId = title.title_id;
			this.titleName = title.title_name;
			this.titleStatus = 'old';
			this.submitBtn = 'UPDATE';
			this.dialog.showForm = true;
		},
		sendTitle() {
			if (this.titleName != '') {
				this.dialog.showForm = false;
				var formData = new FormData();
				formData.append('title_status', this.titleStatus);
				formData.append('shekh_id', this.selectedShekh);
				formData.append('type_id', this.selectedType);
				formData.append('title', this.titleName);
				formData.append('title_id', this.titleId);

				axios
					.post('duruus_title.php', formData)
					.then((data) => {
						if (data.status == 200) {
							var x =
								this.submitBtn == 'SAVE' ? 'Added' : 'Changed';
							this.message = 'Title ' + x + ' Successful !!!';
							this.showSuccess = true;
							this.titleName = '';
							this.processor();
						}
					})
					.catch((error) => {
						alert(error);
					});
			}
		},
		viewDuruus(id) {
			this.dialog.showDuruus = true;
			this.getDuruusAudio(id);
			this.titleId = id;
		},
		confirmDelete(id) {
			this.titleId = id;
			this.dialog.show = true;
		},
		deleteDuruus() {
			axios
				.post(
					'duruus_title.php',
					JSON.stringify({
						title_status: 'block',
						title_id: this.titleId,
					})
				)
				.then((data) => {
					if (data.status == 200) {
						this.dialog.show = false;
						this.showSuccess = true;
						this.message = 'Title Deleted Successful !!!';
						setTimeout(function() {
							this.showSuccess = false;
						}, 2000);
						this.processor();
					}
				})
				.catch(() => {
					this.showError = true;
					this.message = 'Fail To Delete Title, Please Try Again !!!';
				});
		},
		getDuruusAudio(tid) {
			this.isLoading.audios = true;
			axios
				.get('duruus.php?status=byDuruusTitle&tid=' + tid)
				.then((data) => {
					this.isLoading.audios = false;
					this.audioList = data.data.data;
				})
				.catch((error) => {
					this.isLoading.audios = false;
					this.audioList = [];
					console.log(error);
				});
		},
	},
	watch: {
		tabValue() {
			if (this.tabValue == 0) {
				this.btnAddAudio = 'SAVE';
				this.audioSuccess = false;
				this.audioError = false;
				this.file = undefined;
				this.audioTitle = '';
			}
		},
		selectedShekh() {
			this.processor();
		},
		selectedType() {
			this.processor();
		},
		duruusTitles() {
			if (this.duruusTitles) {
				this.noresult.titles = this.duruusTitles.length == 0;
			} else {
				this.noresult.titles = true;
			}
		},
		audioList() {
			if (this.audioList) {
				this.noresult.audios = this.audioList.length == 0;
			} else {
				this.noresult.audios = true;
			}
		},
	},
	computed:{
		activeIndex(){
			return this.$store.state.player.active;
		},
		activeKey(){
			return this.$store.state.player.key=='duruus';
		},
		activeId(){
			let i = this.$store.state.player.active;
			let id = this.$store.state.playlist[i].resource_id??0;
			return id;
		}
	},
	mounted() {
		this.getDuruusType();
		this.getShekh();
	},
};
</script>
<style scoped></style>
