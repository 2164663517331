<template>
	<base-drawer>
		<w-flex column>
			<title-header title="NASAHA"></title-header>
			<main class="grow">
				<w-flex wrap grow>
					<div class="xs12 md12 lg12 xl12" style="overflow: auto;">
						<w-card
							shadow
							class="xs12 md12 lg12 xl12 primary--bg white mb4 pl3 titleCard"
						>
							Nasaha List
							<div
								class="setting bg-main white add ml3"
								@click="showForm = true"
							>
								<i class="mdi mdi-plus"></i>
							</div>
						</w-card>
						<nasaha-list
							@edit-nasaha="editNasaha"
							:is-changed="dataChanged"
						/>
						<nasaha-form
							:content="nasahaContent"
							:show-dialog="showForm"
							@closed="closeForm"
							@load-data="loadData"
						/>
					</div>
				</w-flex>
			</main>
		</w-flex>
	</base-drawer>
</template>
<script>
import NasahaList from '../components/nasaha/nasaha_list.vue';
import NasahaForm from '../components/nasaha/nasaha_form.vue';
export default {
	components: { NasahaList, NasahaForm },
	data: () => ({
		showForm: false,
		noresult: false,
		isLoading: false,
		dataChanged: 0,
		nasahaContent: {
			id: null,
			type: 'new',
			title: '',
			country: ''
		},
	}),

	methods: {
		closeForm() {
			this.showForm = false;
			this.nasahaContent = this.defaultContent();
		},
		editNasaha(nasaha) {
			this.showForm = true;

			this.nasahaContent = {
				type: 'edit',
				id: nasaha.id,
				title: nasaha.title,
				detail: nasaha.detail
			};
		},
		loadData() {
			this.dataChanged += 1;
		},
		defaultContent() {
			return {
				id: null,
				type: 'new',
				title: '',
				country: ''
			};
		},
	},
};
</script>
<style scoped></style>
