<template>
	<w-dialog
		:model-value="showDialog"
		:title="type == 'new' ? 'Add Shekh' : 'Edit Shekh'"
		title-class="bg-main white"
		transition="slide-down"
		persistent="persistent"
		:fullscreen="false"
		:persistent-no-animation="false"
		:width="500"
		style="position:fixed;"
	>
		<w-alert error plain v-if="dialogError"
			><p>
				Fail To {{ type == 'new' ? 'Add' : 'Change' }} Shekh
			</p></w-alert
		>
		<w-alert success plain v-if="dialogSuccess"
			><p>
				Shekh {{ type == 'new' ? 'Added' : 'Changed' }} Successful !!!
			</p></w-alert
		>
		<w-form
			action="#"
			method="post"
			@submit.prevent="addShekh()"
			class="mt2"
		>
			<w-input
				outline="outline"
				placeholder="Shekh Name"
				color="text-main"
				inner-icon-left="mdi mdi-account"
				v-model="name"
				:validators="[validators.required]"
			/>
			<w-input
				outline="outline"
				Placeholder="Shekh Address"
				color="text-main"
				class="mt4"
				inner-icon-left="mdi mdi-file"
				v-model="address"
				:validators="[validators.required]"
			/>
			<w-input
				type="number"
				outline="outline"
				placeholder="Shekh Phone"
				color="text-main"
				class="mt4"
				inner-icon-left="mdi mdi-phone"
				v-model="phone"
				:validators="[validators.required]"
			/>
			<w-input
				outline="outline"
				placeholder="Shekh Email"
				color="text-main"
				inner-icon-left="mdi mdi-email"
				class="mt4"
				v-model="email"
				:validators="[validators.required]"
			/>

			<w-flex class="mt6 text-center">
				<w-button class="" @click="$emit('closed')" bg-color="error"
					>Close</w-button
				>
				<w-button
					v-if="type == 'new'"
					type="submit"
					class="ml5 bg-main"
					bg-color="teal"
					color="white"
					:loading="isLoading"
					>SAVE</w-button
				>
				<w-button
					v-if="type == 'edit'"
					type="submit"
					class="ml5 bg-main"
					bg-color="teal"
					color="white"
					:loading="isLoading"
					>UPDATE</w-button
				>
			</w-flex>
		</w-form>
	</w-dialog>
</template>
<script>
import axios from 'axios';
export default {
	props: {
		showDialog: {
			type: Boolean,
			required: true,
			validators: function(value) {
				return value === true || value === false;
			},
		},
		content: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			type: 'new',
			id: null,
			name: '',
			address: '',
			phone: '',
			email: '',
			isLoading: false,
			validators: {
				required: (value) => !!value || 'This field is required',
			},
			dialogError: false,
			dialogSuccess: false,
		};
	},
	methods: {
		addShekh() {
			if (
				this.name != '' &&
				this.address != '' &&
				this.phone != '' &&
				this.email != ''
			) {
				this.isLoading = true;
				let formData = new FormData();
				formData.append(
					'shekh_status',
					this.type == 'new' ? 'new' : 'old'
				);
				formData.append('shekhId', this.id);
				formData.append('shekhName', this.name);
				formData.append('address', this.address);
				formData.append('phone', this.phone);
				formData.append('email', this.email);
				axios
					.post('shekh.php', formData)
					.then((data) => {
						this.isLoading = false;
						let result = data.data;
						if (result.status == 200) {
							this.$emit('load-data', data.data);
							this.dialogError = false;
							this.dialogSuccess = true;
						} else {
							this.dialogError = true;
							this.dialogSuccess = false;
						}
					})
					.catch((error) => {
						this.isLoading = false;
						alert(error);
					});
			}
		},
	},
	watch: {
		showDialog() {
			this.dialogSuccess = false;
			this.dialogError = false;
			let shekh = this.content;
			this.id = shekh.id;
			this.type = shekh.type;
			this.name = shekh.name;
			this.address = shekh.address;
			this.phone = shekh.phone;
			this.email = shekh.email;
		},
	},
};
</script>
<style scoped></style>
