<template>
	<base-drawer>
		<w-flex column>
			<header style="margin-top: 42px;" v-if="!forms.upload">
				<w-flex class="wrap no-grow text-left">
					<div class="xs6 md6 lg4 xl6 pa1">
						<div class="">
							<w-select
								v-model="selectedQari"
								:items="qariList"
								outline="outline"
								>Choose Qari</w-select
							>
						</div>
					</div>
				</w-flex>
			</header>
			<main class="grow" style="position: absolute;right: 0px;left: 200px;top: 100px;" :style="{'left':$waveui.breakpoint.xs?'0px':'200px'}">
				<w-flex wrap class="ml4" v-if="!forms.upload">
					<div class="xs12 md12 lg12 xl12">
						<w-card
							shadow
							class="xs12 md12 lg12 xl12 primary--bg white mb4 pl3 titleCard"
						>
							Surah List
						</w-card>
						<div v-if="!isLoading">
							<player :qariSurahList="qariSurahList" :isstatic="selectedQari.static??0" :path="selectedQari.id??''" :showBtn="true" @uploadSurah="uploadSurah" />
							<w-flex wrap class="mt0">
								
								<div
									class="text-center"
									v-if="noresult.audios"
									style="margin: 0 auto"
								>
									<img
										src="../assets/noresult.png"
										class="noresult"
										alt=""
									/>
								</div>
								<div
									class="text-center"
									v-if="isLoading.audios"
									style="margin: 0 auto"
								>
									<img
										src="../assets/loader.gif"
										class="loader"
										alt=""
									/>

									
								</div>
							</w-flex>
						</div>
						<div class="text-center" v-if="noresult">
							<img
								src="../assets/noresult.png"
								class="noresult"
								alt=""
							/>
						</div>
						<div class="text-center" v-if="isLoading">
							<img
								src="../assets/loader.gif"
								class="loader"
								alt=""
							/>
						</div>
					</div>
				</w-flex>


				<w-card shadow class="m2" bg-color="white" v-if="forms.upload">
					<w-alert success v-if="audioSuccess"
						>Surah {{btnAddAudio}} Successful</w-alert
					>
					<w-alert error v-if="audioError" dismiss
						>Fail To {{btnAddAudio}} Quran, Please Try
						Again</w-alert
					>
					<form
						action="#"
						method="post"
						@submit.prevent="sendSurah()"
						style="margin: 0 auto;"
					>
						<div class="mt2 mb5">
							<p><span class="text-bold primary">QARI : </span> {{selectedQari.name}}</p>
							<p class="mt2"><span class="text-bold primary">SURAH:</span> {{selectedSurah.arabic + " ( "+selectedSurah.latin+" )"}}</p>
						</div>
						<input
							type="file"
							outline="outline"
							class="mb4"
							id="file"
							ref="file"
							required
							v-on:change="handleFileUpload()"
						/>
						<div>
							<w-button
								class="ma1"
								type="submit"
								bg-color="primary"
								:loading="button1loading"
							>
								<w-icon class="mr1">wi-check</w-icon
								>{{ btnAddAudio }}
							</w-button>
							<w-button bg-color="error" @click="forms.upload=false">Cancel</w-button>
						</div>
						
					</form>
				</w-card>
			</main>
		</w-flex>
	</base-drawer>
</template>
<script>
import axios from 'axios';
import player from './player.vue'
export default {
	components:{
		player
	},
	data: () => ({
		addTitleVisible: false,
		selectedSurah: null,
		selectedQari: [],
		qariList: [],
		qariSurahList: [],
		file: undefined,
		showAudioForm: false,
		button1loading: false,
		btnAddAudio: 'UPLOAD',
		audioSuccess: false,
		audioError: false,
		forms: { show: false, upload: false },
		validators: {
			required: (value) => !!value || 'This field is required',
		},
		noresult: false,
		isLoading: false,
	}),
	methods: {
		getQari() {
			axios
				.get('qari.php?status=all')
				.then((data) => {
					var res = data.data;
					if (res.status == 200) {
						for (var i = 0; i < res.data.length; i++) {
							let qari = res.data[i];
							this.qariList.push({
								label: qari.name,
								value: qari,
							});
						}
					}
				})
				.catch((error) => {
					alert(error);
				});
		},
		getQariSurah() {
			let qari=this.selectedQari.id;
			this.isLoading = true;
			this.noresult = false;
			let formData=new FormData();
			formData.append("status",'surah')
			formData.append("id",qari)
			axios
				.post('qari.php',formData)
				.then((data) => {
					let result = data.data;
					var code = result.status;
					this.isLoading = false;
					if (result.data && code == 200) {
						this.qariSurahList = result.data;
					} else {
						this.qariSurahList = [];
					}
				})
				.catch(() => {
					this.isLoading = false;
					this.qariSurahList = [];
				});
		},
		uploadSurah(obj) {
			this.btnAddAudio = obj.status=='new'?'UPLOAD':"CHANGE";
			this.selectedSurah = obj.audio.id?obj.audio:0;

			this.audioSuccess = false;
			this.audioError = false;
			this.forms.upload = true;
		},
		sendSurah() {
			if (
				this.file
			) {
				this[`button1loading`] = true;
				var formData = new FormData();
				let states = null;
				if (this.btnAddAudio == 'UPLOAD') {
					states=0;
				}
				else if(this.btnAddAudio== "CHANGE"){
					states=1;
				}
				if(states!=null){
					formData.append('status', 'uploadSurah');
					formData.append('surah', this.selectedSurah.id);
					formData.append('audio', this.file);
					formData.append('audioId', 0);
					formData.append('qari', this.selectedQari.id);
					formData.append('userId', 7);
					formData.append('states', states);
					
					axios
						.post('qari.php', formData, {
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						})
						.then((data) => {
							this[`button1loading`] = false;
							let resp = data.data;
							if((resp.status==200 && resp.data.result==1) || (resp.status==200 && states==1)){
								this.forms.upload=false;
								this.getQariSurah();
							}
						})
						.catch(() => {
							this[`button1loading`] = false;
							this.audioError = true;
						});
				}
				else{
					alert("No States FOUND")
				}
			} else {
				this.audioError = true;
			}
		},
		
		handleFileUpload() {
			this.file = this.$refs.file.files[0];
		},
		processor() {
			if (this.selectedQari.id && this.selectedQari.id != '') {
				this.addTitleVisible = true;
				this.getQariSurah();
			} else {
				this.addTitleVisible = false;
			}
		},
	},
	watch: {
		selectedQari() {
			this.processor();
		},
		qariSurahList() {
			this.noresult = this.qariSurahList == null || this.qariSurahList.length == 0;
		}
	},
	mounted() {
		this.getQari();
	},
};
</script>
<style scoped></style>
