<template>
	<base-drawer>
		<w-flex column>
			<title-header title="VIPINDI VYA DARSA"></title-header>
			<main class="grow">
				<w-flex wrap grow>
					<div class="xs12 md12 lg12 xl12" style="overflow: auto;">
						<w-card
							shadow
							class="xs12 md12 lg12 xl12 primary--bg white mb4 pl3 titleCard"
						>
							Darsa List
							<div
								class="setting bg-main white add ml3"
								@click="showForm = true"
							>
								<i class="mdi mdi-plus"></i>
							</div>
						</w-card>
						<darsa-list
							@edit-darsa="editDarsa"
							:is-changed="dataChanged"
						/>
						<darsa-form
							:content="darsaContent"
							:show-dialog="showForm"
							@closed="closeForm"
							@load-data="loadData"
						/>
					</div>
				</w-flex>
			</main>
		</w-flex>
	</base-drawer>
</template>
<script>
import DarsaList from '../components/darsa/list.vue';
import DarsaForm from '../components/darsa/form.vue';
export default {
	components: { DarsaList, DarsaForm },
	data: () => ({
		showForm: false,
		noresult: false,
		isLoading: false,
		dataChanged: 0,
		darsaContent: {
            id: null,
            type: 'new',
            title: '',
            place: '',
            day: '',
            time: '',
        },
        
	}),

	methods: {
		closeForm() {
			this.showForm = false;
			this.darsaContent = this.defaultContent();
		},
		editDarsa(darsa) {
			this.showForm = true;

			this.darsaContent = {
				type: 'edit',
				id: darsa.id,
				title: darsa.mada,
				place: darsa.place,
				day: darsa.siku,
				time: darsa.muda,
			};

		},
		loadData() {
			this.dataChanged += 1;
		},
		defaultContent() {
			return {
				id: null,
				type: 'new',
				title: '',
				place: '',
				day: '',
				time: '',
			};
		},
	},
};
</script>
<style scoped></style>
