<template>
	<section class="grow">
		<!-- TRACK PLAYER -->
		<div class="player" :class="{'active':$store.state.player.showPlayer}">
			<div class="text-center text-lowercase warning" v-if="$store.state.player.error">{{$store.state.player.msg}}</div>
			<div class="player-controls">
				<w-icon class="icon mr1 pa5 white" lg>mdi mdi-shuffle</w-icon>
				<!-- PREVIOUS TRACK BUTTON -->
				<w-icon class="icon mr1 pa5 white" lg @click="prevTrack()">mdi mdi-skip-previous</w-icon>
				<!-- PLAY TRACK BUTTON -->
				<w-icon class="icon mr1 pa5 text-main playpause" lg bg-color="white" v-if="$store.state.player.playBtn"  @click="playpause()">mdi mdi-play</w-icon>
				<!-- PAUSE TRACK BUTTON -->
				<w-icon class="icon mr1 pa5 white--bg text-main playpause" lg v-if="!$store.state.player.playBtn"  @click="playpause()">mdi mdi-pause</w-icon>

				<!-- NEXT TRACK BUTTON -->
				<w-icon class="icon mr1 pa5 white" lg @click="nextTrack('btn')">mdi mdi-skip-next</w-icon>

				<!-- REPEAT TRACK BUTTON -->
				<w-icon class="icon mr1 pa5 white" lg @click="repeatTrack()" v-if="$store.state.player.repeat===0">mdi mdi-repeat-off</w-icon>
				<w-icon class="icon mr1 pa5 white" lg @click="repeatTrack()" v-if="$store.state.player.repeat===1">mdi mdi-repeat-once</w-icon>
				<w-icon class="icon mr1 pa5 white" lg @click="repeatTrack()" v-if="$store.state.player.repeat===2">mdi mdi-repeat</w-icon>

				<div class="volume">
					<w-icon class="icon mr1 pa5 white" lg v-if="volumeValue==0">mdi mdi-volume-mute</w-icon>
					<w-icon class="icon mr1 pa5 white" lg v-else-if="volumeValue<0.1">mdi mdi-volume-low</w-icon>
					<w-icon class="icon mr1 pa5 white" lg v-else-if="volumeValue<0.5">mdi mdi-volume-medium</w-icon>
					<w-icon class="icon mr1 pa5 white" lg v-if="volumeValue>=0.5">mdi mdi-volume-high</w-icon>
					<w-slider class="slider" v-model="volumeValue" :min="$store.state.player.volume.min" :max="$store.state.player.volume.max"></w-slider>
				</div>
				
				
				
			</div>
			<w-slider bg-color="white" color="cyan" v-model="$store.state.player.value" :min="$store.state.player.slider.min" :max="$store.state.player.slider.max" :label-left="$store.state.player.mintime" :label-right="$store.state.player.maxtime" class="white"></w-slider>
		</div>
	</section>
</template>
<script>
export default {
	data: () => ({
        audio: null,
		volumeValue:1,
		sourceDestination:'https://resource.attamayyuz.or.tz/audio/'
	}),
	methods: {
		playNow(i) {

			this.$store.state.player.error = false;
			this.$store.state.player.msg = '';
            this.$store.state.player.active = i
			let current = this.$store.state.playlist[this.$store.state.player.active];
            let path = this.sourceDestination+this.$store.state.player.path +'/' +current.filename
			this.audio.volume = this.volumeValue
			document.title = current.resource_name
            this.audio.setAttribute('src', path);
            this.audio.load();
            // console.log(this.audio)
			
			// console.log("VOLUME : " + this.audio.volume)
            
            this.audio.addEventListener("loadeddata",()=>{
                this.$store.state.player.slider.max=80;
                this.$store.state.player.maxtime = this.secToMin(this.audio.duration)
                this.audio.play();
            })
		},
        playpause(){
            if(this.audio.paused)
                this.audio.play();
            else
                this.audio.pause();
        },
        nextTrack(from){
			if(this.$store.state.player.repeat==0 && this.$store.state.player.active==113){
				return false;
			}
			else if(this.$store.state.player.repeat==1 && from !='btn'){
				this.playNow(this.$store.state.player.active);
				return false;
			}
            if(this.$store.state.playlist.length-1>this.$store.state.player.active)
                this.playNow(this.$store.state.player.active+1)
            else if(this.$store.state.playlist.length-1==this.$store.state.player.active)
                this.playNow(0)
        },
        prevTrack(){
            if(this.$store.state.player.active>0)
                this.playNow(this.$store.state.player.active-1)
            else if(this.$store.state.player.active==0)
                this.playNow(0)
        },
		repeatTrack(){
			let repeat = this.$store.state.player.repeat;
			if(repeat==2)
				repeat = 1;
			else if(repeat==1)
				repeat = 0;
			else if(repeat==0)
				repeat=2
			this.$store.state.player.repeat = repeat;
		},
        secToMin(seconds){
            var min = 0;
            var sec = Math.floor(seconds);
            var time = 0;

            min = Math.floor(sec / 60);

            min = min >= 10 ? min : '0' + min;

            sec = Math.floor(sec % 60);

            sec = sec >= 10 ? sec : '0' + sec;

            time = min + ':' + sec;

            return time;
        },
	},
	watch:{
		volumeValue(val){
			this.audio.volume = val;
		},
		'$store.state.player.audioClicked': function() {
			let active=this.$store.state.player.active;
			this.playNow(active)
		}
	},
	created() {
		this.audio = this.$store.state.audio;
        // CHANGE IF PLAY TIME CHANGE
        this.audio.addEventListener('timeupdate',()=>{ 
            this.$store.state.player.mintime = this.secToMin(this.audio.currentTime)
            this.$store.state.player.value = this.audio.currentTime;
            this.$store.state.player.slider.max = this.audio.duration;
        },false);

        // CHECK IF AUDIO PLAYING
        this.audio.addEventListener("play",()=>{
            this.$store.state.player.playBtn=false
        })

		// CHECK IF AUDIO PAUSED
        this.audio.addEventListener("pause",()=>{
            this.$store.state.player.playBtn=true
        },false)

		// DETECT IF AUDIO ENDED
        this.audio.addEventListener("ended",()=>{
            this.$store.state.player.playBtn=true
            this.$store.state.player.mintime=this.$store.state.player.maxtime="00:00"
            this.nextTrack('ended');
        },false)
		// CHECK IF AN ERROR OCCURED
		this.audio.addEventListener(
			'error',
			(e)=>{
				this.$store.state.player.error = true;
				switch (e.target.error.code) {
					case e.target.error.MEDIA_ERR_ABORTED:
							// 'You aborted the audio playback.';
							this.$store.state.player.msg = 'Audio Was Aborted';

						break;
					case e.target.error.MEDIA_ERR_NETWORK:
							// 'A network error caused the audio to fail, Please Try Again';
							this.$store.state.player.msg = 'Network Error';
						break;
					case e.target.error.MEDIA_ERR_DECODE:
							// 'The audio playback was aborted due to a corruption';
							this.$store.state.player.msg = 'audio corruption';

						break;
					case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
							// 'The audio not be loaded , Please Check Your Internet And Try Again';
							this.$store.state.player.msg = 'Audio Not Loaded'

						break;
					default:
							// 'An unknown error occurred, Please Contact Us Through : info@attamayyuz.or.tz';
							this.$store.state.player.msg = 'Unknown Error,( Please Contact Us )'

						break;
				}
			},
			false
		);
	},
};
</script>
<style scoped>
.player{
	position: fixed;
	right: 0px;
	left: 200px;
	bottom: -200px;
	background: #19bc9d;
	padding: 2px 20px;
	box-shadow: 0 0 2px red;
	margin: 0px auto;
	transition: .5s;
	z-index: 999999;
}
.player.active{
	bottom: 0px;
}
.player .player-controls{
    width: 250px;
    height: auto;
    /* background: darkcyan; */
    display: flex;
	color: white;
	margin: 5px auto;
}
.player .player-controls .icon{
	cursor: pointer;
	background: rgba(255, 255, 255, .05);
}
.player .player-controls .icon.playpause{
	background: white;
	color: #19bc9d;
}
.volume{
	position: relative;
	width: 200px;
	display: inline;
	z-index: 999999;
}
.volume .slider{
	position: absolute;
	left: 40px;
	top: 18px;
	width: 50px;
	z-index: 999999;
}
@media screen and (max-width:600px) {
	.player{
		left: 0px;
	}
	.player .player-controls{
		width: 270px;
	}
}
@media screen and (max-width:450px) {
	.player .player-controls{
		width: 340px;
	}
}
@media screen and (max-width:374px) {
	.player .player-controls{
		width: 260px;
	}
	.player .player-controls .volume .slider{
		position: relative;
		left: 0px;
		top: 0px;
		width: 100%;
		z-index: 999999;
	}
}

</style>
