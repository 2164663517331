<template>
	<base-drawer>
		<w-flex column>
			<header style="margin-top: 42px;">
				<w-flex class="wrap no-grow text-left">
					<div class="xs6 md6 lg4 xl6 pa1">
						<div class="">
							<w-select
								v-model="selectedShekh"
								:items="shekhList"
								outline="outline"
								>Choose Shekh</w-select
							>
						</div>
					</div>
				</w-flex>
			</header>
			<main class="grow">
				<w-flex wrap class="ml4">
					<div class="xs12 md12 lg12 xl12">
						<w-card
							shadow
							class="xs12 md12 lg12 xl12 primary--bg white mb4 pl3 titleCard"
						>
							Ruduud List
							<div
								v-if="addTitleVisible"
								class="setting bg-main white add"
								@click="addRuduud('new')"
							>
								<i class="mdi mdi-plus"></i>
							</div>
						</w-card>
						<div v-if="!isLoading">
							<w-flex wrap class="mt2">
								<div
									class="title-container"
									style="width: 100%;"
								>
									<div
										style="display: none;padding: 0px;margin: 0px;width: 100%;"
										class="audioError"
									>
										<w-alert
											error
											icon-outside
											color="error"
											style="margin: 10px;"
											><div>Error</div></w-alert
										>
									</div>
									<section>
										<w-flex wrap class="play-list">
											<div
												class="lg12 md12 sm12 xs12"
												v-for="(audio,
												index) in ruduudList"
												:key="audio.ruduud_id"
											>
												<card-content
													class="play-list-row titleCard"
													:data-track-row="
														generateIndex(index)
													"
												>
													<div
														class="small-toggle-btn" :class="{'active':index==activeIndex && activeKey && activeId==audio.ruduud_id}"
													>
														<i
															class="small-play-btn" :class="{'small-pause-btn':index==activeIndex && activeKey && activeId==audio.ruduud_id}"
															><span
																class="screen-reader-text"
																>Small toggle
																button</span
															></i
														>
													</div>
													<div
														class="track-number"
													></div>
													<div
														class="track-title text-uppercase"
													>
														<a
															class="playlist-track"
															href="#"
															:data-play-track="
																generateIndex(
																	index
																)
															"
															@click="playNow(index)"
														>
															{{
																audio.ruduud_title
															}}</a
														>
														<span
															class="fa fa-bullseye scale_motion" :class="{'activeDot': index==activeIndex && activeKey && activeId==audio.ruduud_id}"
														></span>
														<div
															title="Edit"
															class="setting info-dark2--bg white edit"
															@click="addRuduud('old',audio)
															"
														>
															<i
																class="mdi mdi-pencil"
															></i>
														</div>
														<div
															title="delete"
															class="setting error--bg white delete"
															@click="
																deleteRuduud(
																	audio.ruduud_id
																)
															"
														>
															<i
																class="mdi mdi-delete"
															></i>
														</div>
													</div>
													<!-- <div
																class="btnDownload"
																@click="
																	downloadAudio(
																		audio.filename,
																		audio.resource_name
																	)
																"
															>
																<i
																	class="fa fa-download"
																></i>
															</div> -->
												</card-content>
											</div>
										</w-flex>
									</section>
								</div>

								<div
									class="text-center"
									v-if="noresult.audios"
									style="margin: 0 auto"
								>
									<img
										src="../assets/noresult.png"
										class="noresult"
										alt=""
									/>
								</div>
								<div
									class="text-center"
									v-if="isLoading.audios"
									style="margin: 0 auto"
								>
									<img
										src="../assets/loader.gif"
										class="loader"
										alt=""
									/>

								</div>
							</w-flex>
						</div>
						<div class="text-center" v-if="noresult">
							<img
								src="../assets/noresult.png"
								class="noresult"
								alt=""
							/>
						</div>
						<div class="text-center" v-if="isLoading">
							<img
								src="../assets/loader.gif"
								class="loader"
								alt=""
							/>
						</div>
					</div>
				</w-flex>

				<w-dialog
					v-model="dialog.showForm"
					:fullscreen="true"
					:width="1000"
					:persistent="false"
					title-class="bg-main white"
					transition="bounce"
				>
					<template #title="">
						<w-icon class="mr2">mdi mdi-plus</w-icon>{{btnAddAudio}} Ruduud
					</template>

					<div class="xs12 md12 lg12 xl12 primary pa1">
						<w-flex wrap class="mt2 justify-center mt10">
							<div
								class="xs12 md6 lg4 xl12 primary pa2 titleCard"
							>
								<w-card shadow class="m2" bg-color="white">
									<w-alert success v-if="audioSuccess"
										>Ruduud {{btnAddAudio}}d Successful</w-alert
									>
									<w-alert error v-if="audioError" dismiss
										>Fail To {{btnAddAudio}} Ruduud, Please Try
										Again</w-alert
									>
									<w-form
										action="#"
										method="post"
										@submit.prevent="sendRuduud()"
										style="margin: 0 auto;"
									>
										<w-input
											v-model="audioTitle"
											type="text"
											label="Audio Title"
											outline="outline"
											class="mb4"
											:validators="[validators.required]"
										/>
										<input
											type="file"
											outline="outline"
											class="mb4"
											id="file"
											ref="file"
											required="true"
											v-on:change="handleFileUpload()"
										/>
										<w-textarea
											no-autogrow="no-autogrow"
											v-model="ruduudDesc"
											rows="6"
											:validators="[validators.required]"
											>Description</w-textarea
										>
										<w-button
											class="ma1"
											type="submit"
											bg-color="primary"
											:loading="button1loading"
										>
											<w-icon class="mr1">wi-check</w-icon
											>{{ btnAddAudio }}
										</w-button>
									</w-form>
								</w-card>
							</div>
						</w-flex>
					</div>

					<template #actions="">
						<div class="spacer"></div>
						<w-button @click="dialog.showForm = false"
							>Close</w-button
						>
					</template>
				</w-dialog>
			</main>
		</w-flex>
	</base-drawer>
</template>
<script>
import axios from 'axios';

export default {
	data: () => ({
		showPlayer: false,
		loadScript: 0,
		showDrawer: false,
		addTitleVisible: false,
		selectedShekh: [],
		shekhList: [],
		ruduudList: [],
		audioTitle: '',
		ruduudId: null,
		ruduudDesc: '',
		file: undefined,
		showAudioForm: false,
		button1loading: false,
		btnAddAudio: 'SAVE',
		audioSuccess: false,
		audioError: false,
		dialog: { width: 500, show: false, showForm: false },
		validators: {
			required: (value) => !!value || 'This field is required',
		},
		statusCode: '',
		noresult: false,
		isLoading: false,
	}),
	methods: {
		playNow(i) {
			let s = this.selectedShekh;

			this.$store.state.playlist = this.ruduudList;
			this.$store.state.playlist.map((x)=>{
				x.filename = x.ruduud_file;
				x.resource_name = x.ruduud_title;
				return x;
			});
			this.$store.state.player.key = 'ruduud';
			this.$store.state.player.active = i;
			this.$store.state.player.audioClicked +=1;
			this.$store.state.player.path="ruduud/"+s;
			
			this.$store.state.player.showPlayer = true;
		},
		generateIndex(index) {
			return ++index;
		},
		getShekh() {
			axios
				.get('shekh.php?status=all')
				.then((data) => {
					var res = data.data;
					if (res.status == 200) {
						for (var i = 0; i < res.data.length; i++) {
							let sh = res.data[i];
							this.shekhList.push({
								label: sh.shekh_name,
								value: sh.shekh_id,
							});
						}
					}
				})
				.catch((error) => {
					alert(error);
				});
		},
		getRuduud(shekh) {
			this.isLoading = true;
			this.noresult = false;
			axios
				.get('ruduud.php?status=ruduudByShekh&id=' + shekh)
				.then((data) => {
					let result = data.data;
					var code = result.status;
					this.isLoading = false;
					if (result.data && code == 200) {
						this.ruduudList = result.data;
					} else {
						this.ruduudList = [];
					}
				})
				.catch(() => {
					this.isLoading = false;
					this.ruduudList = [];
				});
		},
		addRuduud(status, audio={}) {
			this.btnAddAudio = status=='new'?'SAVE':"UPDATE";
			this.titleStatus = status;
			this.audioTitle = audio.ruduud_title??'';
			this.ruduudId = audio.ruduud_id??'';
			this.ruduudDesc = audio.ruduud_desc??'';

			this.audioSuccess = false;
			this.audioError = false;
			this.dialog.showForm = true;
		},
		sendRuduud() {
			if (
				this.audioTitle != '' &&
				this.selectedShekh != '' &&
				this.ruduudDesc != ''
			) {
				this[`button1loading`] = true;
				var formData = new FormData();
				formData.append('titleName', this.audioTitle);
				if (this.btnAddAudio == 'SAVE') {
					formData.append('status', 'new');
					formData.append('title', this.audioTitle);
					formData.append('audio', this.file);
					formData.append('desc', this.ruduudDesc);
					formData.append('shekhId', this.selectedShekh);
					formData.append('userId', 7);
				} else if (this.btnAddAudio == 'UPDATE') {
					formData.append('status', 'old');
					formData.append('title', this.audioTitle);
					formData.append('ruduudId', this.ruduudId);
					formData.append('desc', this.ruduudDesc);
				}
				axios
					.post('ruduud.php', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((data) => {
						this[`button1loading`] = false;
						let resp = data.data;
						if (resp.status == 200) {
							this.getRuduud(this.selectedShekh);
							this.audioSuccess = true;
							this.dialog.showForm = false;
						} else {
							this.audioError = true;
						}
					})
					.catch(() => {
						this[`button1loading`] = false;
						this.audioError = true;
					});
			} else {
				this.audioError = true;
			}
		},
		deleteRuduud(id) {
			let conf = confirm('Ruduud Will Be Deleted !!!');
			if (conf) {
				axios
					.post(
						'ruduud.php',
						JSON.stringify({
							status: 'block',
							ruduud_id: id,
						})
					)
					.then((data) => {
						let res = data.data;
						if (res.status == 200) {
							this.getRuduud(this.selectedShekh);
							alert('Ruduud Deleted Successfull!!!');
						} else {
							alert(
								'Fail To Delete Ruduud, Please Try Again Letter !!!'
							);
						}
					})
					.catch(() => {
						alert(
							'Fail To Delete Ruduud, Please Try Again letter!!!'
						);
					});
			}
		},
		handleFileUpload() {
			this.file = this.$refs.file.files[0];
		},
		processor() {
			if (this.selectedShekh != '') {
				this.addTitleVisible = true;
				this.getRuduud(this.selectedShekh);
			} else {
				this.addTitleVisible = false;
			}
		},
	},
	watch: {
		selectedShekh() {
			this.processor();
		},
		ruduudList() {
			this.noresult =
				this.ruduudList == null || this.ruduudList.length == 0;
			this.loadScript += 1;
		},
	},
	computed:{
		activeIndex(){
			return this.$store.state.player.active;
		},
		activeKey(){
			return this.$store.state.player.key=='ruduud';
		},
		activeId(){
			let i = this.$store.state.player.active;
			let id = this.$store.state.playlist[i].ruduud_id??0;
			return id;
		}
	},
	mounted() {
		this.getShekh();
	},
};
</script>
<style scoped></style>
