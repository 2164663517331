<template>
	<aside class=" white primary--bg">
		<div class="nav-menu-wrap primary--bg">
			<div class="nav-menu">
				<w-button
					class="close"
					v-if="$waveui.breakpoint.xs"
					@click="$emit('drawerOpen', false)"
					sm="sm"
					outline="outline"
					round="round"
					color="primary"
					icon="wi-cross"
				></w-button>
				<w-list
					:items="sections"
					nav="nav"
					color="white"
					@item-select="onItemClick"
				></w-list>
				<w-button
					outline="outline"
					class="primary--bg mt9 mb5 white"
					style="background: #19bc9d;color: white;font-weight: bold;width: 90%;text-align: left;"
					@click="logout()"
					><w-icon>mdi mdi-logout</w-icon>Logout</w-button
				>
			</div>
		</div>
	</aside>
</template>
<script>
export default {
	props: {
		drawerOpen: { type: Boolean, default: true },
	},
	data() {
		return {
			sections: [
				{
					label: 'Dashboard',
					route: '/dashboard',
				},
				{
					label: 'Shekh',
					route: '/shekh',
				},
				{
					label: 'Duruus',
					route: '/duruus',
				},
				{ label: 'Khutba', route: '/khutba' },
				{ label: 'Mihadhara', route: '/mihadhara' },
				{ label: 'Ruduud', route: '/ruduud' },
				{ label: 'Makala', route: '/makala' },
				{ label: 'Qari', route: '/qari' },
				{ label: 'Quran', route: '/quran' },
				{ label: 'Vitabu', route: '/books' },
				{ label: 'Nasaha', route: '/nasaha' },
				{ label: 'Mashairi', route: '/mashairi' },
				{ label: 'Vipindi Vya Darsa', route: '/darsa' },
				// { label: 'Radio', route: '/radio' },
				// { label: 'Subscriber', route: '/subscribers' },
			],
		};
	},
	methods: {
		onItemClick() {
			this.$emit('update:drawerOpen', false);
			// setTimeout(() => {
			// 	document.documentElement.scrollTop =
			// 		document.querySelector('.content-wrap').offsetTop - 42;
			// }, 200);
		},
		logout() {
			if (!this.isOut()) {
				if (confirm('System Will Logout !!!')) {
					this.clearStore();
					this.$router.push('/');
				}
			} else {
				this.clearStore();
				this.$router.push('/');
			}
		},
		clearStore() {
			this.$store.state.isLogin = false;
			localStorage.removeItem('isLogin');
			localStorage.removeItem('token');
			localStorage.removeItem('loginUser');
		},
		isOut() {
			return (
				localStorage.getItem('isLogin') == null ||
				localStorage.getItem('isLogin') != 'true'
			);
		},
	},
	mounted() {
		if (this.isOut()) {
			this.logout();
		}
	},
};
</script>
<style scoped>
aside {
	width: 200px;
	position: relative;
	top: 0px;
	bottom: 0px;
	padding-top: 0px;

	/* display: none; */
}
.nav-menu-wrap {
	position: fixed;
	min-width: 200px;
	max-width: 200px;
	border-right: 0px solid #ddd;
	top: 50px;
	bottom: 0px;
}
div.nav-menu {
	height: 100vh;
	overflow: auto;
}
div.nav-menu .w-list__item-label {
	padding: 6px;
	padding-left: 4;
	font-size: 1em;
	font-weight: normal;
	border-left: 2px solid transparent;
}
div.nav-menu .w-list__item-label:before {
	display: none;
}
div.nav-menu
	.w-list__item-label:not(.w-list__item-label--disabled):focus:before,
div.nav-menu
	.w-list__item-label:not(.w-list__item-label--disabled):hover:before {
	display: block;
}
div.nav-menu .w-list__item-label.router-link-active {
	border-left-color: red;
	border-width: 2px;
	font-weight: 700;
}
div.nav-menu .w-list__item-label.router-link-active:before {
	display: block;
	opacity: 0.15;
}
div.nav-menu .w-list__item-label:before {
	left: -2px;
}
div.nav-menu .w-list__item--parent > .w-list__item-label {
	text-transform: uppercase;
	font-weight: bold;
}
div.nav-menu .w-tag {
	padding: 2px 4px 1px;
}
.content-wrap .nav-menu-wrap {
	padding-top: 24px;
	margin-right: 32px;
}
.content-wrap .nav-menu {
	position: sticky;
	top: 60px;
}

@media screen and (max-width: 600px) {
	.nav-menu-wrap {
		top: 0px;
		bottom: 0px;
	}
}
</style>
