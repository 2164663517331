<template>
	<section>
		<div class="text-center" v-if="noresult">
			<img src="../assets/noresult.png" class="noresult" alt="" />
		</div>
		<div class="text-center" v-if="loading">
			<img src="../assets/loader.gif" class="loader" alt="" />
		</div>
	</section>
</template>
<script>
export default {
	props: {
		noresult: { type: Boolean, required: true },
		loading: { type: Boolean, required: true },
	},
};
</script>
