<template>
	<w-app block id="app">
		<router-view class="content"></router-view>
	</w-app>
</template>

<script>
// import BaseDrawer from './components/BaseDrawer.vue';
export default {
	name: 'App',

	methods: {
		logout() {
			if (!this.isOut()) {
				if (confirm('System Will Logout !!!')) {
					this.clearStore();
					this.$router.push('/');
				}
			} else {
				this.clearStore();
				this.$router.push('/');
			}
		},
		clearStore() {
			this.$store.state.isLogin = false;
			localStorage.removeItem('isLogin');
			localStorage.removeItem('token');
			localStorage.removeItem('loginUser');
		},
		isOut() {
			return (
				localStorage.getItem('isLogin') == null ||
				localStorage.getItem('isLogin') != 'true'
			);
		},
	},
	computed: {
		isOutData() {
			return (
				localStorage.getItem('isLogin') == null ||
				localStorage.getItem('isLogin') != 'true'
			);
		},
	},
};
</script>

<style>
body,
html {
	color: #333;
	background: #fafafa url('./assets/bg.jpeg');
	font-family: 'Patua One', sans-serif;
	margin: 0;
	padding: 0;
}
body {
	overflow-x: auto;
}
.bg-main {
	background: #19bc9d;
}
.bg-secondary {
	background: #1d416d;
}
.text-main {
	color: #19bc9d;
}
.w-app,
header,
aside,
main {
	margin: 0px;
	padding: 0px;
	text-transform: uppercase;
	color: #666;
	border: 0px solid rgba(0, 0, 0, 0.07);
	overflow-y: auto;
}
/* .w-app {
	background-color: #fffeed;
} */
header {
	background-color: #e9f8fe;
	min-height: 60px;
	line-height: 60px;
	padding-left: 10px;
	margin-top: 42px;
}

main {
	background: url('/assets/bg.jpeg');
	padding: 10px;
}

.noresult,
.loader {
	margin-top: 10%;
	width: 190px;
}
.loader {
	width: 50px;
}

.titleCard {
	position: relative;
	word-wrap: break-word;
	overflow: hidden;
	background: #f5f5f5;
}
.titleCard .setting {
	position: absolute;
	right: 5px;
	top: 100px;
	line-height: 50px;
	height: 100%;
	width: 45px;
	text-align: center;
	transition: 0.3s;
	cursor: pointer;
}
.titleCard .setting.edit {
	transition: 0.5s;
	right: 60px;
}
.titleCard .setting.view {
	transition: 0.7s;
	right: 115px;
}
.titleCard .setting.add {
	top: 0px;
	line-height: 45px;
	right: 0px;
	font-size: 24px;
}
.titleCard:hover .setting {
	top: 0px;
}
@media screen and (max-width: 600px) {
	.asideLarge {
		display: none;
	}
}

/* AUDIO LIST & PLAYER */
@import '//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/css/font-awesome.css';
.title-container .screen-reader-text {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

.title-container .disabled {
	color: #666;
	cursor: default;
}

.title-container .show {
	display: inline-block !important;
}

.title-container {
	font-family: arial, helvetica, sans-serif;
	margin: 0 auto;
	margin-top: 20px;
	padding-bottom: 90px;
	/* width: 500px; */
}
.title-container section {
	bottom: -53px;
}
.title-container .play-list .play-list-row {
	position: relative;
	word-wrap: break-word;
	overflow: hidden;
	height: 40px;
	line-height: 40px;
	font-size: 16px;
	margin: 10px;
	padding-left: 5px;
}
.title-container .play-list .play-list-row .small-toggle-btn {
	top: 5px;
	left: -50px;
	border: 0px solid #d9d9d9;
	border-radius: 0px;
	cursor: pointer;
	display: inline-block;
	height: 100%;
	line-height: 20px;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 20px;
	height: 20px;
	color: white;
	background: #19bc9d;
	border-radius: 40px;
	font-size: 9px;
	transition: .5s;
}
.title-container .play-list .play-list-row .small-toggle-btn.active, 
.title-container .play-list .play-list-row:hover .small-toggle-btn{
	left: 0px;
}
.title-container .play-list .play-list-row .small-toggle-btn .small-play-btn {
	display: inline-block;
}
.title-container
	.play-list
	.play-list-row
	.small-toggle-btn
	.small-play-btn:before {
	content: '\f04b';
	font: 0.85em 'FontAwesome';
}
.title-container .play-list .play-list-row .small-toggle-btn {
	display: inline-block;
}

.title-container
	.play-list
	.play-list-row
	.small-toggle-btn
	.small-pause-btn:before {
	content: '\f04c';
	font: 0.85em 'FontAwesome';
}
.title-container .audioTitle div {
	font-size: 15px;
}
.title-container .play-list .play-list-row .track-title a {
	display: block;
	position: absolute;
	top: 0px;
	right: 0px;
	left: 36px;
	bottom: 0px;
	line-height: 40px;
	padding-left: 5px;
	text-transform: uppercase;
}
.title-container .play-list .play-list-row .track-title.active-track a {
	/* font-weight: bold; */
	color: red;
}
.title-container .play-list .play-list-row .track-title .playlist-track {
	color: rgba(0, 0, 0, 0.8);
	text-decoration: none;
	display: block;
}
.title-container .play-list .play-list-row .track-title .playlist-track:hover {
	text-decoration: none;
	/* color: red; */
}
.title-container .play-list .play-list-row .track-title .activeDot {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 10px;
	color: red;
}
.title-container
	.play-list
	.play-list-row
	.track-title.active-track
	.activeDot {
	display: block;
}
.scale_motion {
	animation-name: track;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}
@keyframes track {
	0% {
		padding-top: 3px;
	}
	50% {
		padding-top: 18px;
	}
	100% {
		padding-top: 3px;
	}
}

.btnDownload {
	position: absolute;
	top: 40px;
	right: 30px;
	width: 40px;
	height: 40px;
	background: #19bc9d;
	color: white;
	text-align: center;
	transition: 0.4s;
	cursor: pointer;
	line-height: 40px;
}
.play-list-row:hover .btnDownload {
	top: 0px;
}

.title-container .player {
	position: fixed;
	left: 0px;
	right: 0px;
	background: #fff;
	height: 70px;
	transition: 0.3s;
	bottom: -70px;
	/* position: relative; */
	/* Small devices (tablets, 768px and up) */
	/* Medium devices (desktops, 992px and up) */
	/* /* Large devices (large desktops, 1200px and up) */
	*zoom: 1;
}

/* AUDIO OM PLAYER */

.title-container .player.showPlayer,
.title-container section.showPlayer {
	bottom: 0px;
	background: #f5f5f5;
	box-shadow: 0 0 1px #19bc9d inset;
}
.title-container .player .sub-container {
	position: relative;
	/* background: cyan; */
	width: 100%;
	max-width: 720px;
	height: 65px;
	margin: 0 auto;
}
.title-container .player .large-toggle-btn {
	border: 1px solid #d9d9d9;

	position: absolute;
	font-size: 8px;
	overflow: hidden;
	padding: 0px;
	position: absolute;
	left: 34px;
	text-align: center;
	width: 35px;
	height: 35px;
	border-radius: 40px;
	line-height: 35px;
	background: #19bc9d;
	color: white;
	bottom: 0px;
	/* box-shadow: 0 0 3px rgb(71, 156, 156); */
}
.title-container .player .large-toggle-btn .large-play-btn {
	cursor: pointer;
	display: inline-block;
	position: relative;
}
.title-container .player .large-toggle-btn .large-play-btn:before {
	content: '\f04b';
	font: 1.5em/1.75 'FontAwesome';
}
.title-container .player .large-toggle-btn .large-pause-btn {
	cursor: pointer;
	display: inline-block;
	position: relative;
}
.title-container .player .large-toggle-btn .large-pause-btn:before {
	content: '\f04c';
	font: 1.5em/1.75 'FontAwesome';
}
.title-container .player .info-box {
	bottom: 1px;
	left: 0px;
	right: 0px;
	position: absolute;
	height: 43px;

	/* background: #19bc9d; */
	border-radius: 2px;
}
.title-container .player .info-box .track-info-box {
	float: left;
	font-size: 12px;
	visibility: hidden;
	width: 100%;
	text-align: center;
	*zoom: 1;
	height: 11px;
}
.title-container .player .info-box .track-info-box .track-title-text {
	position: absolute;
	top: -10px;
	color: #19bc9d;
	width: 100%;
	/* background:red; */
}
.title-container .player .info-box .track-info-box .audio-time {
	position: absolute;
	top: 20px;
	right: 0px;
	width: 100px;
	color: #19bc9d;
}
.title-container .player .info-box .track-info-box:before,
.title-container .player .info-box .track-info-box:after {
	content: ' ';
	display: table;
}
.title-container .player .info-box .track-info-box:after {
	clear: both;
	display: block;
	font-size: 0;
	height: 0;
	visibility: hidden;
}
.title-container .player .progress-box {
	float: left;
	position: absolute;
	left: 120px;
	right: 100px;
}
.title-container .player .progress-box .progress-cell {
	height: 12px;
	position: absolute;
	left: 0px;
	right: 0px;
	top: 20px;
}
.title-container .player .progress-box .progress-cell .progress {
	background: white;
	border: 1px solid #d9d9d9;
	height: 10px;
	/* position: relative; */
	width: auto;
}
.title-container
	.player
	.progress-box
	.progress-cell
	.progress
	.progress-buffer {
	background: #19bc9d;
	height: 100%;
	width: 0;
	border-radius: 2px;
}
.title-container
	.player
	.progress-box
	.progress-cell
	.progress
	.progress-indicator {
	background: #19bc9d;
	border: 3px solid white;
	border-radius: 12px;
	cursor: pointer;
	height: 17px;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: -3px;
	width: 17px;
	box-shadow: 0 0 2px rgb(92, 74, 74);
}
.title-container .player .controls-box {
	bottom: 10px;
	left: 0px;
	position: absolute;
	width: 100px;
	/* background: blue; */
	height: 30px;
	bottom: 0px;
}
.title-container .player .controls-box .previous-track-btn {
	cursor: pointer;
	display: inline-block;
	color: #19bc9d;
	position: absolute;
	left: 5px;
}
.title-container .player .controls-box .previous-track-btn:before {
	content: '\f049';
	font: 1em 'FontAwesome';
}
.title-container .player .controls-box .next-track-btn {
	cursor: pointer;
	display: inline-block;
	margin-left: 20px;
	color: #19bc9d;
	position: absolute;
	right: 0px;
}
.title-container .player .controls-box .next-track-btn:before {
	content: '\f050';
	font: 1em 'FontAwesome';
}
.title-container .player:before,
.title-container .player:after {
	content: ' ';
	display: table;
}
.title-container .player:after {
	clear: both;
	display: block;
	font-size: 0;
	height: 0;
	visibility: hidden;
}
</style>
