<template>
	<w-toolbar class="main-toolbar" fixed="fixed">
		<router-link
			class="w-flex no-grow fill-height align-center home-link"
			to="/"
		>
			<w-icon class="wave-logo mr3" size="3em">wi-wave</w-icon
			><span>Attamayyuz</span>
		</router-link>
		<div class="spacer"></div>
		<w-tooltip z-index="20">
			<template #activator="{ on }">
				<div class="ml1" v-on="on">
					<router-link to="/notification">
						<w-icon lg="lg">mdi mdi-bell</w-icon>
					</router-link>
				</div> </template
			>notification
		</w-tooltip>
		<w-tooltip z-index="20">
			<template #activator="{ on }"
				><router-link class="ml3" v-on="on" to="/account">
					<w-icon lg="lg"
						>mdi mdi-account-circle-outline</w-icon
					></router-link
				></template
			>
			<div class="text-center">Account</div>
		</w-tooltip>

		<w-button
			class="ml2 mr-1 hamburger-menu"
			v-if="$waveui.breakpoint.xs"
			@click="$emit('drawerOpen', !drawerOpen)"
			large="large"
			text="text"
			round="round"
			:icon="drawerOpen ? 'wi-cross' : 'mdi mdi-menu'"
			>just open</w-button
		>
	</w-toolbar>
</template>

<script>
export default {
	props: {
		drawerOpen: { type: Boolean, default: false },
	},

	computed: {},
};
</script>

<style>
div.main-toolbar {
	background: #19bc9d;
	height: 42px;
	padding-top: 0;
	padding-bottom: 0;
	z-index: 190;
}
div.main-toolbar a {
	color: white;
}
</style>
