<template>
	<base-drawer>
		<w-flex column>
			<title-header title="DASHBOARD"></title-header>
			<main class="grow">
				<w-flex wrap class="text-center">
					<div class="xs12 md6 lg4 xl2 pa1">
						<div class="primary--bg py3 white">
							<w-icon>mdi mdi-account</w-icon>
							<p class="mt5">SHEKH</p>
						</div>
					</div>
					<div class="xs12 md6 lg4 xl2 pa1">
						<div class="primary--bg py3 white">
							<w-icon>mdi mdi-book</w-icon>
							<p class="mt5">BOOKS</p>
						</div>
					</div>
					<div class="xs12 md6 lg4 xl2 pa1">
						<div class="primary--bg py3 white">
							<w-icon>mdi mdi-file</w-icon>
							<p class="mt5">DURUUS</p>
						</div>
					</div>
				</w-flex>
			</main>
		</w-flex>
	</base-drawer>
</template>
<script>
export default {
	methods: {
		// logout() {
		// 	if (!this.isOut()) {
		// 		if (confirm('System Will Logout !!!')) {
		// 			this.clearStore();
		// 			this.$router.push('/');
		// 		}
		// 	} else {
		// 		this.clearStore();
		// 		this.$router.push('/');
		// 	}
		// },
		// clearStore() {
		// 	this.$store.state.isLogin = false;
		// 	localStorage.removeItem('isLogin');
		// 	localStorage.removeItem('token');
		// 	localStorage.removeItem('loginUser');
		// },
		// isOut() {
		// 	return (
		// 		localStorage.getItem('isLogin') == null ||
		// 		localStorage.getItem('isLogin') != 'true'
		// 	);
		// },
		getToken() {
			return JSON.parse(localStorage.getItem('loginUser')).token;
		},
	},
	computed: {
		getLogin() {
			let obj = { name: 'User' };
			return this.$store.state.user ?? obj;
		},
	},
	mounted() {
		// if (this.isOut()) {
		// 	this.logout();
		// }
	},
};
</script>
<style scoped>
/* Demo styles - can be discarded. */

@media screen and (min-width: 900px) {
	/* aside {
		display: block;
	} */
}
</style>
