<template>
	<div>
		<w-card
			:tile="true"
			shadow
			class="xs12 md12 lg12 xl12 primary mt1 pa0 titleCard"
			v-for="qari in qariList"
			:key="qari.id"
		>
			<p>
				<w-icon class="mr1">wi-check</w-icon>
				{{ qari.name }}
			</p>
			<div
				class="setting info-dark2--bg white edit"
				@click="editQari(qari)"
			>
				<i class="mdi mdi-pencil"></i>
			</div>
			<div
				class="setting error--bg white delete"
				@click="deleteConfirm(qari)"
			>
				<i class="mdi mdi-delete"></i>
			</div>
		</w-card>
		<site-loader
			:noresult="progress == 0"
			:loading="progress == 1"
		></site-loader>
		<confirm
			:show="showConfirm"
			:btnLoad="isDeleting"
			@on-confirm="deleteQari"
		/>
	</div>
</template>
<script>
import axios from 'axios';
import confirm from '../confirm.vue';
export default {
	components: { confirm },
	props: ['isChanged'],
	emits: ['edit-qari'],
	data() {
		return {
			qariList: [],
			progress: null,
			showConfirm: false,
			toDelete: null,
			isDeleting: false,
		};
	},
	methods: {
		async getQari() {
			this.progress = 1;
			try {
				const response = await axios.post('qari.php?status=all');
				let res = response.data.status;
				this.qariList = res == 200 ? response.data.data : [];
				// alert(JSON.stringify(this.qariList))
				this.progress = null;
			} catch (error) {
				this.progress = null;
				console.log(error);
			}
		},
		editQari(qari) {
			this.$emit('edit-qari', qari);
		},
		deleteConfirm(qari) {
			this.showConfirm = true;
			this.toDelete = qari;
		},
		deleteQari(result) {
			if (result) {
				this.isDeleting = true;
				var formData = new FormData();
				formData.append('status', -1);
				formData.append('qariId', this.toDelete.id);
				formData.append('qariName', '');
				formData.append('country', '');
				axios
					.post(
						'qari.php',
						formData
					)
					.then((data) => {
						this.showConfirm = false;
						this.isDeleting = false;
						let result = data.data;
						if (result.status == 200) {
							this.getQari();
						} else {
							alert('Fail To Delete');
						}
					})
					.catch((error) => {
						this.showConfirm = false;
						this.isDeleting = false;
						alert('Fail To Delete\n ' + error);
					});
			} else {
				if (!this.isDeleting) {
					this.showConfirm = false;
				}
			}
		},
	},
	watch: {
		isChanged() {
			this.qariList = [];
			this.getQari();
		},
	},
	mounted() {
		this.getQari();
	},
};
</script>
<style scoped></style>
