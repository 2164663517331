<template>
	<div>
		<w-card
			:tile="true"
			shadow
			class="xs12 md12 lg12 xl12 primary mt1 pa0 titleCard"
			v-for="shekh in shekhList"
			:key="shekh.shekh_id"
		>
			<p>
				<w-icon class="mr1">wi-check</w-icon>
				{{ shekh.shekh_name }}
			</p>
			<div class="setting info--bg white view" @click="viewDuruus(shekh)">
				<i class="mdi mdi-eye"></i>
			</div>
			<div
				class="setting info-dark2--bg white edit"
				@click="editShekh(shekh)"
			>
				<i class="mdi mdi-pencil"></i>
			</div>
			<div
				class="setting error--bg white delete"
				@click="deleteConfirm(shekh)"
			>
				<i class="mdi mdi-delete"></i>
			</div>
		</w-card>
		<site-loader
			:noresult="progress == 0"
			:loading="progress == 1"
		></site-loader>
		<confirm
			:show="showConfirm"
			:btnLoad="isDeleting"
			@on-confirm="deleteShekh"
		/>
	</div>
</template>
<script>
import axios from 'axios';
import confirm from '../confirm.vue';
export default {
	components: { confirm },
	props: ['isChanged'],
	emits: ['edit-shekh'],
	data() {
		return {
			shekhList: [],
			progress: null,
			showConfirm: false,
			toDelete: null,
			isDeleting: false,
		};
	},
	methods: {
		async getShekh() {
			this.progress = 1;
			try {
				const response = await axios.post('shekh.php?status=all');
				let res = response.data.status;
				this.shekhList = res == 200 ? response.data.data : [];
				this.progress = null;
			} catch (error) {
				this.progress = null;
				console.log(error);
			}
		},
		editShekh(shekh) {
			this.$emit('edit-shekh', shekh);
		},
		deleteConfirm(shekh) {
			this.showConfirm = true;
			this.toDelete = shekh;
		},
		deleteShekh(result) {
			if (result) {
				this.isDeleting = true;
				axios
					.post(
						'shekh.php',
						JSON.stringify({
							shekh_status: 'block',
							shekh_id: this.toDelete.shekh_id,
						})
					)
					.then((data) => {
						this.showConfirm = false;
						this.isDeleting = false;
						let result = data.data;
						if (result.status == 200) {
							this.getShekh();
						} else {
							alert('Fail To Delete');
						}
					})
					.catch((error) => {
						this.showConfirm = false;
						this.isDeleting = false;
						alert('Fail To Delete\n ' + error);
					});
			} else {
				if (!this.isDeleting) {
					this.showConfirm = false;
				}
			}
		},
	},
	watch: {
		isChanged() {
			this.shekhList = [];
			this.getShekh();
		},
	},
	mounted() {
		this.getShekh();
	},
};
</script>
<style scoped></style>
