<template>
	<header style="margin-top: 42px;">
		<w-flex class="text-left">
			<div class="xs6 md6 lg4 xl6">
				<div class="">
					{{ title }}
				</div>
			</div>
		</w-flex>
	</header>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
	},
};
</script>
