<template>
	<section>
		<div class="login-form">
			<form
				action="#"
				method="post"
				autocomplete="off"
				@submit.prevent="login()"
			>
				<!-- <h2 class="text-center">Log in</h2> -->
				<div class="form-group">
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="fa fa-paper-plane"></i>
							</span>
						</div>
						<input
							type="email"
							class="form-control"
							name="email"
							placeholder="Email"
							required="required"
							v-model="email"
						/>
					</div>
				</div>
				<div class="form-group">
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="fa fa-lock"></i>
							</span>
						</div>
						<input
							type="password"
							class="form-control"
							name="password"
							placeholder="Password"
							required="required"
							v-model="pass"
						/>
					</div>
				</div>
				<div class="form-group">
					<w-button
						type="submit"
						class="btn btn-block login"
						:loading="isLoading"
					>
						Log in
					</w-button>
				</div>
				<!-- <div class="clearfix">
					<router-link to="/recover" class="float-left text-danger"
						>Forgot Password ?</router-link
					>
				</div> -->
			</form>
		</div>

		<w-notification
			v-model="notification.show"
			:timeout="notification.timeout"
			error
			plain="plain"
			top
			shadow="shadow"
			transition="scale-fade"
			>{{ notification.msg }}</w-notification
		>
	</section>
</template>
<style src="bootstrap/dist/css/bootstrap.min.css" scoped></style>
<script>
import axios from 'axios';
export default {
	data() {
		return {
			email: '',
			pass: '',
			isLoading: false,
			notification: {
				show: false,
				timeout: 4000,
				msg: '',
				position: ['true', 'right'],
			},
		};
	},
	methods: {
		login() {
			if (this.email != '' && this.pass != '') {
				this.isLoading = true;
				var formData = new FormData();
				formData.append('email', this.email);
				formData.append('password', this.pass);
				axios
					.post(
						'https://staffapi.attamayyuz.or.tz/login.php',
						// 'http://localhost/myAPI/login.php',
						formData,
						{headers:{"Content-Type":"multipart/form-data"}}
					)
					.then((data) => {
						this.isLoading = false;
						let result = data.data;
						if (result.status == 201) {
							localStorage.setItem('isLogin', true);
							localStorage.setItem('token', result.token);
							this.$store.commit(
								'setUser',
								JSON.stringify(result.data)
							);
							this.$router.push('/dashboard');
						} else if (result.status == 400) {
							this.notification.msg = result.message;
							this.notification.show = true;
						} else {
							this.notification.msg = "Fail To Login, Please Try Agin Later";
							this.notification.show = true;
						}
					})
					.catch((error) => {
						alert(error)
						this.isLoading = false;
						this.notification.msg =
							'An Error Occured, Please Check Connection !!!';
						this.notification.show = true;
					});
			} else {
				this.notification.msg =
					'Please Enter Username And Password !!!';
				this.notification.show = true;
			}
		},
		isLogin() {
			return (
				localStorage.getItem('isLogin') != null &&
				localStorage.getItem('isLogin') === 'true'
			);
		},
	},

	mounted() {
		if (this.isLogin()) {
			this.$router.push('/dashboard');
		}
	},
};
</script>
<style scoped>
.login-form {
	width: 340px;
	margin: 0px auto;
	font-size: 15px;
	padding-top: 10%;
}
.login-form form {
	margin-bottom: 15px;
	background: white;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	padding: 30px;
}
.login-form h2 {
	margin: 0 0 15px;
	color: #19bc9d;
}
.btn,
.form-control,
.form-control:focus,
.input-group-text {
	box-shadow: none !important;

	border: 1px solid #19bc9d !important;
}
.form-control,
.btn {
	min-height: 38px;
	border-radius: 2px;
	box-shadow: none;
}
.btn {
	font-size: 15px;
	font-weight: bold;
	color: white !important;
}
.login-form a {
	text-decoration: none;
}
.btn.login {
	background: #19bc9d;
}

.input-group-prepend span i {
	width: 18px;
}
.recover {
	color: #1d416d;
}
a.back {
	text-decoration: none;
	color: brown;
}
::-moz-focus-inner {
	border: 0;
	padding: 0;
}
</style>
