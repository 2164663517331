<template>
	<w-dialog
		:model-value="show"
		title="Warning"
		title-class="error"
		transition="bounce"
		persistent="persistent"
		:width="450"
		style="position: fixed;"
	>
		Are you sure you want to delete?
		<template #actions="">
			<div class="spacer"></div>
			<w-button
				class="mr2"
				@click="$emit('on-confirm', false)"
				bg-color="error"
				width="90px"
				>No</w-button
			>
			<w-button
				@click="$emit('on-confirm', true)"
				bg-color="success"
				:loading="btnLoad"
				width="90px"
			>
				Delete</w-button
			>
		</template>
	</w-dialog>
</template>
<script>
export default {
	props: {
		show: { type: Boolean, required: true },
		btnLoad: { type: Boolean, required: true },
	},
};
</script>
