<template>
	<div>
		<w-card
			:tile="true"
			shadow
			class="xs12 md12 lg12 xl12 primary mt1 pa0 titleCard"
			v-for="book in bookList"
			:key="book.id"
		>
			<p>
				<w-icon class="mr1">wi-check</w-icon>
				{{ book.title }}
			</p>
			<a :href="'https://resource.attamayyuz.or.tz/pdf/books/' + book.url" target="_blank" class="setting info--bg white view">
				<i class="mdi mdi-eye"></i>
			</a>
			<div
				class="setting info-dark2--bg white edit"
				@click="editBook(book)"
			>
				<i class="mdi mdi-pencil"></i>
			</div>
			<div
				class="setting error--bg white delete"
				@click="deleteConfirm(book)"
			>
				<i class="mdi mdi-delete"></i>
			</div>
		</w-card>
		<site-loader
			:noresult="progress == 0"
			:loading="progress == 1"
		></site-loader>
		<confirm
			:show="showConfirm"
			:btnLoad="isDeleting"
			@on-confirm="deleteBook"
		/>
	</div>
</template>
<script>
import axios from 'axios';
import confirm from '../confirm.vue';
export default {
	components: { confirm },
	props: ['isChanged'],
	emits: ['edit-book'],
	data() {
		return {
			bookList: [],
			progress: null,
			showConfirm: false,
			toDelete: null,
			isDeleting: false,
		};
	},
	methods: {
		async getBook() {
			this.progress = 1;
			try {
				let formData = new FormData();
				formData.append('status','all');
				const response = await axios.post('book.php',formData,{headers:{"Content-Type":"multipart/form-data"}});
				// alert(JSON.stringify(response.data))
				let res = response.data.status;
				this.bookList = res == 200 ? response.data.data : [];
				this.progress = null;
			} catch (error) {
				this.progress = null;
				console.log(error);
			}
		},
		editBook(book) {
			this.$emit('edit-book', book);
		},
		deleteConfirm(book) {
			this.showConfirm = true;
			this.toDelete = book;
		},
		deleteBook(result) {
			if (result) {
				this.isDeleting = true;
				let formData = new FormData();
				formData.append('status', 'delete');
				formData.append('book', this.toDelete.id);
				axios
					.post(
						'book.php',
						formData
					)
					.then((data) => {
						this.showConfirm = false;
						this.isDeleting = false;
						let result = data.data;
						if (result.status == 200) {
							this.getBook();
						} else {
							alert('Fail To Delete');
						}
					})
					.catch((error) => {
						this.showConfirm = false;
						this.isDeleting = false;
						alert('Fail To Delete\n ' + error);
					});
			} else {
				if (!this.isDeleting) {
					this.showConfirm = false;
				}
			}
		},
	},
	watch: {
		isChanged() {
			this.bookList = [];
			this.getBook();
		},
	},
	mounted() {
		this.getBook();
	},
};
</script>
<style scoped></style>
