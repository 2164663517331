<template>
	<w-drawer
		v-model="showDrawer"
		push-content="push-content"
		:overlay-opacity="0.15"
		right="right"
		width="200px"
	>
		<template #pushable="">
			<w-app id="app" class="w-flex row">
				<toolbar />
				<sidemenu />
				<w-flex column>
					<header>
						<!-- <w-button
							@click="showDrawer = !showDrawer"
							outline="outline"
							color="primary"
							>Show drawer</w-button
						> -->
						Notifications
					</header>
					<main class="grow">Main content</main>
				</w-flex>
			</w-app>
		</template>
		<div class="ma0">
			<sidemenu />
		</div>
	</w-drawer>
</template>
<script>
export default {
	data: () => ({
		showDrawer: false,
	}),
};
</script>
<style scoped></style>
