import { createStore } from 'vuex';

const store = createStore({
	state() {
		return {
			user: null,
			isLogin: false,
			token: null,
			activeDot: null,
			audio: new Audio(),
			playlist: [],
			player:{
				key:'',
				showPlayer: false,
				mintime: "00:00",
				maxtime: "00:00",
				playBtn: true,
				active: null,
				repeat: 2,
				value: 0,
				error: false,
				msg: '',
				slider: {
					min: 0,
					max: 88
				},
				volume:{
					max:1,
					min:0
				},
				path:'',
				audioClicked: 0
			}
		};
	},
	mutations: {
		setUser(_, payload) {
			localStorage.setItem('loginUser', payload);
		}
	}
});

export default store;
