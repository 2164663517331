<template>
	<base-drawer>
		<w-flex column>
			<title-header title="BOOKS"></title-header>
			<main class="grow">
				<w-flex wrap grow>
					<div class="xs12 md12 lg12 xl12" style="overflow: auto;">
						<w-card
							shadow
							class="xs12 md12 lg12 xl12 primary--bg white mb4 pl3 titleCard"
						>
							Books List
							<div
								class="setting bg-main white add ml3"
								@click="showForm = true"
							>
								<i class="mdi mdi-plus"></i>
							</div>
						</w-card>
						<book-list
							@edit-book="editBook"
							:is-changed="dataChanged"
						/>
						<book-form
							:content="BookContent"
							:show-dialog="showForm"
							@closed="closeForm"
							@load-data="loadData"
						/>
					</div>
				</w-flex>
			</main>
		</w-flex>
	</base-drawer>
</template>
<script>
import BookList from '../components/books/BookList.vue';
import BookForm from '../components/books/BookForm.vue';
export default {
	components: { BookList, BookForm },
	data: () => ({
		showForm: false,
		noresult: false,
		isLoading: false,
		dataChanged: 0,
		BookContent: {
			id: null,
			type: 'new',
			title: '',
			detail: '',
			pdf: '',
			email: '',
		},
	}),

	methods: {
		addBook() {
			this.showForm = true;
			this.BookContent = this.defaultContent();
		},
		closeForm() {
			this.showForm = false;
			this.BookContent = this.defaultContent();
		},
		editBook(book) {
			// alert(JSON.stringify(book));
			this.showForm = true;

			this.BookContent = {
				type: 'edit',
				id: book.id,
				title: book.title,
				detail: book.descs,
				pdf: book.url
			};
		},
		loadData() {
			this.dataChanged += 1;
		},
		defaultContent() {
			return {
				id: null,
				type: 'new',
				title: '',
				detail: '',
				pdf: ''
			};
		},
	},
};
</script>
<style scoped></style>
