<template>
	<w-dialog
		:model-value="showDialog"
		:title="type == 'new' ? 'Add Darsa' : 'Edit Darsa'"
		title-class="bg-main white"
		transition="slide-down"
		persistent="persistent"
		:fullscreen="false"
		:persistent-no-animation="false"
		:width="500"
		style="position:fixed;"
	>
		<w-alert error plain v-if="dialogError"
			><p>
				Fail To {{ type == 'new' ? 'Add' : 'Change' }} Darsa
			</p></w-alert
		>
		<w-alert success plain v-if="dialogSuccess"
			><p>
				Darsa {{ type == 'new' ? 'Added' : 'Changed' }} Successful !!!
			</p></w-alert
		>
		<w-form
			action="#"
			method="post"
			@submit.prevent="addDarsa()"
			class="mt2"
		>
			<w-input
				outline="outline"
				placeholder="Mada"
				color="text-main"
				inner-icon-left="mdi mdi-account"
				v-model="title"
				:validators="[validators.required]"
			/>
			<w-input
				outline="outline"
				Placeholder="pahala"
				color="text-main"
				class="mt4"
				inner-icon-left="mdi mdi-file"
				v-model="place"
				:validators="[validators.required]"
			/>

			<w-input
				outline="outline"
				Placeholder="Siku"
				color="text-main"
				class="mt4"
				inner-icon-left="mdi mdi-file"
				v-model="day"
				:validators="[validators.required]"
			/>

			<w-input
				outline="outline"
				Placeholder="Muda"
				color="text-main"
				class="mt4"
				inner-icon-left="mdi mdi-file"
				v-model="time"
				:validators="[validators.required]"
			/>
			

			<w-flex class="mt6 text-center">
				<w-button class="" @click="$emit('closed')" bg-color="error"
					>Close</w-button
				>
				<w-button
					v-if="type == 'new'"
					type="submit"
					class="ml5 bg-main"
					bg-color="teal"
					color="white"
					:loading="isLoading"
					>SAVE</w-button
				>
				<w-button
					v-if="type == 'edit'"
					type="submit"
					class="ml5 bg-main"
					bg-color="teal"
					color="white"
					:loading="isLoading"
					>UPDATE</w-button
				>
			</w-flex>
		</w-form>
	</w-dialog>
</template>
<script>
import axios from 'axios';
export default {
	props: {
		showDialog: {
			type: Boolean,
			required: true,
			validators: function(value) {
				return value === true || value === false;
			},
		},
		content: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			type: 'new',
			id: null,
			title: '',
			place: '',
			day: '',
			time: '',
			isLoading: false,
			validators: {
				required: (value) => !!value || 'This field is required',
			},
			dialogError: false,
			dialogSuccess: false,
		};
	},
	methods: {
		addDarsa() {
			if (
				this.title != '' &&
				this.detail != ''
			) {
				this.isLoading = true;
				let formData = new FormData();
				formData.append(
					'status',
					this.type == 'new' ? 0 : 1
				);
				formData.append('darsaId', this.id??0);
				formData.append('title', this.title);
				formData.append('pahala', this.place);
				formData.append('siku', this.day);
				formData.append('muda', this.time);
	
				axios
					.post('darsa.php', formData)
					.then((data) => {
						this.isLoading = false;
						let result = data.data;
						if (result.status == 200) {
							this.$emit('load-data', data.data);
							this.dialogError = false;
							this.dialogSuccess = true;
						} else {
							this.dialogError = true;
							this.dialogSuccess = false;
						}
					})
					.catch((error) => {
						this.isLoading = false;
						alert(error);
					});
			}
		},
	},
	watch: {
		showDialog() {
			this.dialogSuccess = false;
			this.dialogError = false;
			let darsa = this.content;
			this.id = darsa.id;
			this.title = darsa.title
			this.type = darsa.type;
			this.place = darsa.place
			this.day = darsa.day
			this.time = darsa.time
		},
	}
};
</script>
<style scoped></style>
