<template>
	<div>
		<w-card
			:tile="true"
			shadow
			class="xs12 md12 lg12 xl12 primary mt1 pa0 titleCard"
			v-for="shairi in shairiList"
			:key="shairi.id"
		>
			<p>
				<w-icon class="mr1">wi-check</w-icon>
				{{ shairi.title }}
			</p>
			<div
				class="setting info-dark2--bg white edit"
				@click="editShairi(shairi)"
			>
				<i class="mdi mdi-pencil"></i>
			</div>
			<div
				class="setting error--bg white delete"
				@click="deleteConfirm(shairi)"
			>
				<i class="mdi mdi-delete"></i>
			</div>
		</w-card>
		<site-loader
			:noresult="progress == 0"
			:loading="progress == 1"
		></site-loader>
		<confirm
			:show="showConfirm"
			:btnLoad="isDeleting"
			@on-confirm="deleteShairi"
		/>
	</div>
</template>
<script>
import axios from 'axios';
import confirm from '../confirm.vue';
export default {
	components: { confirm },
	props: ['isChanged'],
	emits: ['edit-shairi'],
	data() {
		return {
			shairiList: [],
			progress: null,
			showConfirm: false,
			toDelete: null,
			isDeleting: false,
		};
	},
	methods: {
		async getMashairi() {
			this.progress = 1;
			try {
                var form = new FormData();
                form.append('status','all');
				const response = await axios.post('mashairi.php', form);
				let res = response.data.status;
				this.shairiList = res == 200 ? response.data.data : [];
				// alert(JSON.stringify(response))
				this.progress = null;
			} catch (error) {
				this.progress = null;
				console.log(error);
			}
		},
		editShairi(shairi) {
			this.$emit('edit-shairi', shairi);
		},
		deleteConfirm(shairi) {
			this.showConfirm = true;
			this.toDelete = shairi;
		},
		deleteShairi(result) {
			if (result) {
				this.isDeleting = true;
				var formData = new FormData();
				formData.append('status', -1);
				formData.append('mashairiId', this.toDelete.id);
				formData.append('title', '');
				formData.append('details', '');
				axios
					.post(
						'mashairi.php',
						formData
					)
					.then((data) => {
						this.showConfirm = false;
						this.isDeleting = false;
						let result = data.data;
						if (result.status == 200) {
							this.getMashairi();
						} else {
							alert('Fail To Delete');
						}
					})
					.catch((error) => {
						this.showConfirm = false;
						this.isDeleting = false;
						alert('Fail To Delete\n ' + error);
					});
			} else {
				if (!this.isDeleting) {
					this.showConfirm = false;
				}
			}
		},
	},
	watch: {
		isChanged() {
			this.shairiList = [];
			this.getMashairi();
		},
	},
	mounted() {
		this.getMashairi();
	},
};
</script>
<style scoped></style>
