<template>
	<section class="grow">
		<section class="title-container">
			<w-flex wrap class="play-list">
				<div
					class="lg3 md4 sm6 xs12"
					v-for="(audio,
					index) in qariSurahList"
					:key="audio.id"
				>
					<card-content
						class="play-list-row titleCard" :class="{' warning--bg':audio.added==0 && isstatic==0}"
						:data-track-row="
							generateIndex(index)
						"
					>
						<div
							class="small-toggle-btn" :class="{'active':index==player.active}"
						>
							<i
								class="small-play-btn" :class="{'small-pause-btn':index==player.active}"
								><span
									class="screen-reader-text"
									>Small toggle
									button</span
								></i
							>
						</div>
						<div
							class="track-number"
						></div>
						<div
							class="track-title text-uppercase"
						>
							<a
								class="playlist-track"
								href="#"
								:data-play-track="
									generateIndex(
										index
									)
								"
								@click="playNow(index)"
							>
								{{
									audio.id+" : " + audio.arabic
								}}</a
							>
							<span
								class="fa fa-bullseye scale_motion" :class="{'activeDot':index==player.active}"
							></span>
							<div
								v-if="audio.added==0 && showBtn && isstatic==0"
								title="Upload Surah"
								class="setting info--bg white delete"
								@click="$emit('uploadSurah',{status:'new',audio:audio})
								"
							>
								<i
									class="mdi mdi-upload"
								></i>
							</div>
							<div
								v-if="audio.added==1 && showBtn && isstatic==0"
								title="Change Audio"
								class="setting info-dark2--bg white delete"
								@click="$emit('uploadSurah',{status:'old',audio:audio})
								"
							>
								<i
									class="mdi mdi-pencil"
								></i>
							</div>
							
						</div>
					</card-content>
				</div>
			</w-flex>
		</section>

		<!-- TRACK PLAYER -->
		<div class="player" :class="{'active':player.active!=null}">
			<div class="text-center text-lowercase warning" v-if="player.error">{{player.msg}}</div>
			<div class="player-controls">
				<w-icon class="icon mr1 pa5 white" lg>mdi mdi-shuffle</w-icon>
				<!-- PREVIOUS TRACK BUTTON -->
				<w-icon class="icon mr1 pa5 white" lg @click="prevTrack()">mdi mdi-skip-previous</w-icon>
				<!-- PLAY TRACK BUTTON -->
				<w-icon class="icon mr1 pa5 text-main playpause" lg bg-color="white" v-if="player.playBtn"  @click="playpause()">mdi mdi-play</w-icon>
				<!-- PAUSE TRACK BUTTON -->
				<w-icon class="icon mr1 pa5 white--bg text-main playpause" lg v-if="!player.playBtn"  @click="playpause()">mdi mdi-pause</w-icon>

				<!-- NEXT TRACK BUTTON -->
				<w-icon class="icon mr1 pa5 white" lg @click="nextTrack('btn')">mdi mdi-skip-next</w-icon>

				<!-- REPEAT TRACK BUTTON -->
				<w-icon class="icon mr1 pa5 white" lg @click="repeatTrack()" v-if="player.repeat===0">mdi mdi-repeat-off</w-icon>
				<w-icon class="icon mr1 pa5 white" lg @click="repeatTrack()" v-if="player.repeat===1">mdi mdi-repeat-once</w-icon>
				<w-icon class="icon mr1 pa5 white" lg @click="repeatTrack()" v-if="player.repeat===2">mdi mdi-repeat</w-icon>

				<div class="volume">
					<w-icon class="icon mr1 pa5 white" lg v-if="volumeValue==0">mdi mdi-volume-mute</w-icon>
					<w-icon class="icon mr1 pa5 white" lg v-else-if="volumeValue<0.1">mdi mdi-volume-low</w-icon>
					<w-icon class="icon mr1 pa5 white" lg v-else-if="volumeValue<0.5">mdi mdi-volume-medium</w-icon>
					<w-icon class="icon mr1 pa5 white" lg v-if="volumeValue>=0.5">mdi mdi-volume-high</w-icon>
					<w-slider class="slider" v-model="volumeValue" :min="player.volume.min" :max="player.volume.max"></w-slider>
				</div>
				
				
				
			</div>
			<w-slider bg-color="white" color="cyan" v-model="player.value" :min="player.slider.min" :max="player.slider.max" :label-left="player.mintime" :label-right="player.maxtime" class="white"></w-slider>
		</div>
	</section>
</template>
<script>
export default {
	props:{
		qariSurahList: {
			required: true,
			type: Array
		},
		path:{
			required: true,
			type: String
		},
		isstatic:{
			required: true,
			type: Number
		},
		showBtn:{
			default: false,
			type: Boolean
		}
	},
	data: () => ({
        audio: null,
        player:{
            mintime: "00:00",
            maxtime: "00:00",
            playBtn: true,
            active: null,
			repeat: 2,
            value: 0,
			error: false,
			msg: '',
            slider: {
                min: 0,
                max: 88
            },
			volume:{
				max:1,
				min:0
			}
        },
		volumeValue:1,
		sourceDestination:'https://resource.attamayyuz.or.tz/audio/'
	}),
	methods: {
		playNow(i) {
			this.player.error = false;
			this.player.msg = '';
            this.player.active = i
			let current = this.qariSurahList[this.player.active];
            let path = this.sourceDestination+'quran/' +this.path +'/' +this.getFilename(current.id)
			this.audio.volume = this.volumeValue
			document.title = current.latin
            this.audio.setAttribute('src', path);
            this.audio.load();
            // console.log(this.audio)
			
			// console.log("VOLUME : " + this.audio.volume)
            
            this.audio.addEventListener("loadeddata",()=>{
                this.player.slider.max=80;
                this.player.maxtime = this.secToMin(this.audio.duration)
                this.audio.play();
            })
		},
        playpause(){
            if(this.audio.paused)
                this.audio.play();
            else
                this.audio.pause();
        },
        nextTrack(from){
			if(this.player.repeat==0 && this.player.active==113){
				return false;
			}
			else if(this.player.repeat==1 && from !='btn'){
				this.playNow(this.player.active);
				return false;
			}
            if(this.qariSurahList.length-1>this.player.active)
                this.playNow(this.player.active+1)
            else if(this.qariSurahList.length-1==this.player.active)
                this.playNow(0)
        },
        prevTrack(){
            if(this.player.active>0)
                this.playNow(this.player.active-1)
            else if(this.player.active==0)
                this.playNow(0)
        },
		repeatTrack(){
			let repeat = this.player.repeat;
			if(repeat==2)
				repeat = 1;
			else if(repeat==1)
				repeat = 0;
			else if(repeat==0)
				repeat=2
			this.player.repeat = repeat;
		},
        secToMin(seconds){
            var min = 0;
            var sec = Math.floor(seconds);
            var time = 0;

            min = Math.floor(sec / 60);

            min = min >= 10 ? min : '0' + min;

            sec = Math.floor(sec % 60);

            sec = sec >= 10 ? sec : '0' + sec;

            time = min + ':' + sec;

            return time;
        },
		generateIndex(index) {
			return ++index;
		},
		getFilename(i){
			let c = localStorage.getItem("soundC");
			let change="";
			if(i.length==1)
				change="00";
			else if(i.length==2)
				change="0";

			return change+i+".mp3?v="+c;
		},
		soundCache(){
			localStorage.setItem("soundC",new Date());
		}
	},
	watch:{
		volumeValue(val){
			this.audio.volume = val;
		}
	},
	created() {
		this.audio = this.$store.state.audio;
        // CHANGE IF PLAY TIME CHANGE
        this.audio.addEventListener('timeupdate',()=>{ 
            this.player.mintime = this.secToMin(this.audio.currentTime)
            this.player.value = this.audio.currentTime;
            this.player.slider.max = this.audio.duration;
        },false);
        // CHECK IF AUDIO PLAYING
        this.audio.addEventListener("play",()=>{
            this.player.playBtn=false
        })
		// CHECK IF AUDIO PAUSED
        this.audio.addEventListener("pause",()=>{
            this.player.playBtn=true
        },false)
		// DETECT IF AUDIO ENDED
        this.audio.addEventListener("ended",()=>{
            this.player.playBtn=true
            this.player.mintime=this.player.maxtime="00:00"
            this.nextTrack('ended');
        },false)
		// CHECK IF AN ERROR OCCURED
		this.audio.addEventListener(
			'error',
			(e)=>{
				this.player.error = true;
				switch (e.target.error.code) {
					case e.target.error.MEDIA_ERR_ABORTED:
							// 'You aborted the audio playback.';
							this.player.msg = 'Audio Was Aborted';

						break;
					case e.target.error.MEDIA_ERR_NETWORK:
							// 'A network error caused the audio to fail, Please Try Again';
							this.player.msg = 'Network Error';
						break;
					case e.target.error.MEDIA_ERR_DECODE:
							// 'The audio playback was aborted due to a corruption';
							this.player.msg = 'audio corruption';

						break;
					case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
							// 'The audio not be loaded , Please Check Your Internet And Try Again';
							this.player.msg = 'Audio Not Loaded'

						break;
					default:
							// 'An unknown error occurred, Please Contact Us Through : info@attamayyuz.or.tz';
							this.player.msg = 'Unknown Error,( Please Contact Us )'

						break;
				}
			},
			false
		);
	},
};
</script>
<style scoped>
.player{
	position: fixed;
	right: 0px;
	left: 200px;
	bottom: -200px;
	background: #19bc9d;
	padding: 2px 20px;
	box-shadow: 0 0 2px red;
	margin: 0px auto;
	transition: .5s;
}
.player.active{
	bottom: 0px;
}
.player .player-controls{
    width: 250px;
    height: auto;
    /* background: darkcyan; */
    display: flex;
	color: white;
	margin: 5px auto;
}
.player .player-controls .icon{
	cursor: pointer;
	background: rgba(255, 255, 255, .05);
}
.player .player-controls .icon.playpause{
	background: white;
	color: #19bc9d;
}
.volume{
	position: relative;
	width: 200px;
	display: inline;
}
.volume .slider{
	position: absolute;
	left: 40px;
	top: 18px;
	width: 50px;
}
@media screen and (max-width:600px) {
	.player{
		left: 0px;
	}
	.player .player-controls{
		width: 270px;
	}
}
@media screen and (max-width:450px) {
	.player .player-controls{
		width: 340px;
	}
}
@media screen and (max-width:374px) {
	.player .player-controls{
		width: 260px;
	}
	.player .player-controls .volume .slider{
		position: relative;
		left: 0px;
		top: 0px;
		width: 100%;
	}
}

</style>
