<template>
	<w-drawer
		v-model="showDrawer"
		@update:model-value="$waveui.breakpoint.xs"
		push-content="push-content"
		:overlay-opacity="0.15"
		left="left"
		width="200px"
	>
		<template #pushable="" class="content-wrap">
			<toolbar @drawerOpen="toggleDrawer" />
			<w-app id="app" class="w-flex row">
				<sidemenu class="asideLarge" />
				<slot></slot>
			</w-app>
			<player/>
		</template>
		<div class="ma0">
			<sidemenu />
		</div>
	</w-drawer>
</template>
<script>
import player from './audio_player.vue';
export default {
	components:{
		player
	},
	data() {
		return { showDrawer: false };
	},
	methods: {
		toggleDrawer(res) {
			this.showDrawer = res;
		},
	},
	watch: {
		showDrawer() {
			if (this.showDrawer) {
				document.querySelector('body').style = 'overflow: hidden;';
			} else {
				document.querySelector('body').style = 'overflow: auto;';
			}
		},
	},
	updated() {
		this.showDrawer = this.showDrawer && this.$waveui.breakpoint.xs;
	},
	created() {
		document.querySelector('body').style = 'overflow: auto;';
	},
};
</script>
<style scoped></style>
