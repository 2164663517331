<template>
	<base-drawer>
		<w-flex column>
			<title-header title="SHEKH"></title-header>
			<main class="grow">
				<w-flex wrap grow>
					<div class="xs12 md12 lg12 xl12" style="overflow: auto;">
						<w-card
							shadow
							class="xs12 md12 lg12 xl12 primary--bg white mb4 pl3 titleCard"
						>
							Shekh List
							<div
								class="setting bg-main white add ml3"
								@click="showForm = true"
							>
								<i class="mdi mdi-plus"></i>
							</div>
						</w-card>
						<shekh-list
							@edit-shekh="editShekh"
							:is-changed="dataChanged"
						/>
						<shekh-form
							:content="shekhContent"
							:show-dialog="showForm"
							@closed="closeForm"
							@load-data="loadData"
						/>
					</div>
				</w-flex>
			</main>
		</w-flex>
	</base-drawer>
</template>
<script>
import ShekhList from '../components/shekh/ShekhList.vue';
import ShekhForm from '../components/shekh/ShekhForm.vue';
export default {
	components: { ShekhList, ShekhForm },
	data: () => ({
		showForm: false,
		noresult: false,
		isLoading: false,
		dataChanged: 0,
		shekhContent: {
			id: null,
			type: 'new',
			name: '',
			address: '',
			phone: '',
			email: '',
		},
	}),

	methods: {
		addShekh() {
			this.showForm = true;
			this.shekhContent = this.defaultContent();
		},
		closeForm() {
			this.showForm = false;
			this.shekhContent = this.defaultContent();
		},
		editShekh(shekh) {
			this.showForm = true;

			this.shekhContent = {
				type: 'edit',
				id: shekh.shekh_id,
				name: shekh.shekh_name,
				address: shekh.shekh_address,
				phone: shekh.shekh_phone,
				email: shekh.shekh_email,
			};
		},
		loadData() {
			this.dataChanged += 1;
		},
		defaultContent() {
			return {
				id: null,
				type: 'new',
				name: '',
				address: '',
				phone: '',
				email: '',
			};
		},
	},
};
</script>
<style scoped></style>
